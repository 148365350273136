import { Warning } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';

if (!window.imageCache) {
    window.imageCache = new Map();
    window.imageCacheMeta = {
        size: 0,
        maxSize: 150,
        storage: 0,
        lastUsed: new Map(),
        manageCallback: () => {
            if (window.imageCacheMeta.size > window.imageCacheMeta.maxSize || window.imageCacheMeta.storage > window.imageCacheMeta.maxStorage) {
                console.log("cache is full, removing oldest entries");
                while (window.imageCacheMeta.size > window.imageCacheMeta.maxSize || window.imageCacheMeta.storage > window.imageCacheMeta.maxStorage) {
                    let oldest = null;
                    let oldestTime = Date.now();
                    for (let [key, value] of window.imageCacheMeta.lastUsed) {
                        if (value < oldestTime) {
                            oldest = key;
                            oldestTime = value;
                        }
                    }
                    window.imageCache.delete(oldest);
                    window.imageCacheMeta.size = window.imageCache.size;
                    window.imageCacheMeta.storage = window.imageCacheMeta.storage - window.imageCacheMeta.lastUsed.get(oldest);
                    window.imageCacheMeta.lastUsed.delete(oldest);

                    console.log("removed from cache:", oldest);
                    // deallocate memory
                    URL.revokeObjectURL(oldest);
                }
            }
        },
        clear: () => {
            window.imageCache = new Map();
            window.imageCacheMeta.size = 0;
            window.imageCacheMeta.storage = 0;
            window.imageCacheMeta.lastUsed = new Map();
        }
    }
}

function Image(props) {
    const { esrc, eCallback, ...other } = props;
    const [error, setError] = useState(null);
    const [dsrc, setDsrc] = useState(null);
    const [loading, setLoading] = useState(true);
    const cache = useRef(null);  // Use useRef for the cache

    useEffect(() => {
        if (!props.src) {
            setLoading(false); // No src, so not loading
            return;
        }

        setLoading(true);
        setError(null); // Reset error on new src
        let isMounted = true; // Flag to prevent setting state after unmount

        const loadImage = async () => {
            try {
                if (!cache.current) {
                    cache.current = await caches.open('image-cache');
                }

                if (props.src.includes("undefined")) {
                    throw new Error('URL is undefined');
                }

                if (window.imageCache.has(props.src)) {
                    if (isMounted) {
                        console.log("using in-memory cache");
                        setDsrc(window.imageCache.get(props.src));
                        setLoading(false);
                    }
                    return;
                }

                let response = await cache.current.match(props.src);

                if (!response) {
                    console.log("fetching and caching image");
                    response = await fetch(props.src);

                    if (!response.ok) {
                        throw new Error(`Network response was not ok: ${response.status}`);
                    }

                    // Crucial:  Only cache successful responses.  Do *not* cache errors!
                    cache.current.put(props.src, response.clone()).catch(cacheError => {
                        console.error("Error caching image:", cacheError);
                        //  Don't set a global error here; just log it.  The fetch itself will handle the error below.
                    });
                } else {
                    console.log("using disk cache");
                }


                const blob = await response.blob();
                let objectURL = URL.createObjectURL(blob);

                window.imageCache.set(props.src, objectURL);
                window.imageCacheMeta.size = window.imageCache.size;
                window.imageCacheMeta.storage += blob.size;
                window.imageCacheMeta.lastUsed.set(props.src, Date.now());
                window.imageCacheMeta.manageCallback();


                if (isMounted) {
                    setDsrc(objectURL);
                    setLoading(false);
                }
            } catch (err) {
                console.error("Error loading image:", err);
                if (isMounted) {
                    setError(true);
                    setLoading(false);
                    //  Don't setDsrc(props.src).  If the fetch failed, we *don't* want to display anything, and we definitely
                    //  don't want to risk an infinite loop by trying to display the failing URL again.
                }
            }
        };

        loadImage();

        return () => {
            isMounted = false; // Prevent setting state after unmount
            if (localStorage.getItem("disableCache") === "true") {
                URL.revokeObjectURL(dsrc); // deallocate because we're not caching
                window.imageCache.delete(props.src);
                window.imageCacheMeta.size = window.imageCache.size;
                window.imageCacheMeta.storage -= window.imageCacheMeta.lastUsed.get(props.src);
                window.imageCacheMeta.lastUsed.delete(props.src);
                window.imageCacheMeta.manageCallback();
            }
        };
    }, [props.src, esrc]); // Only re-run if props.src or esrc changes


    if (!props.src) {
        return <></>;
    }

    if (loading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    ...props.style,
                }}
            >
                <CircularProgress size={40} color="primary" />
            </div>
        );
    }

    if (error) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    ...props.style,
                }}
            >
                <Warning
                    style={{
                        color: 'red',
                        fontSize: 40,
                        zIndex: 1,
                        padding: 5,
                    }}
                />
            </div>
        );
    }

    return (
        <img
            src={dsrc}
            {...other}
            onError={() => {
                if (eCallback) {
                    eCallback();
                }
                setError(true);
            }}
        />
    );
}

export default Image;