import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, InputLabel, LinearProgress, ListItemIcon, Menu, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
import util from '../util';
import Markdown from '../components/Markdown';
import { Add, BugReport, CloseOutlined, Construction, ContentPaste, Delete, Download, DownloadDone, History, HistoryOutlined, Info, PlayArrow, Refresh, Settings, Sync, Task, Update, Upgrade, Warning, Web } from '@mui/icons-material';
import zIndex from '@mui/material/styles/zIndex';
import { callBugReportModal, callLoadingLine, loadingCaller, snackCaller, theme } from '../App';
import Image from '../components/Image';

const Home = () => {
    const navigate = useNavigate();
    const [games, SetGames] = useState(window.games || [])
    const setGames = (g) => {
        // remove duplicates
        g = g.filter((game, index, self) =>
            index === self.findIndex((t) => (
                t.game.appID === game.game.appID && t.game.name === game.game.name
            ))
        )

        SetGames(g)
    }
    const [playTimes, setPlayTimes] = useState([])
    const [selectedGame, setSelectedGame] = useState(0)
    const [isGameInstalled, setIsGameInstalled] = useState(false);
    const [installInProgress, setInstallInProgress] = useState(false);
    const [migrationRequired, setMigrationRequired] = useState(false)
    const [isGameUpdate, setIsGameUpdate] = useState(false);
    const [requiredUpdate, setRequiredUpdate] = useState(false);
    const [infoModalVisible, setInfoModalVisible] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [settingsModalVisible, setSettingsModalVisible] = useState(false);
    const [contextMenu, setContextMenu] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [removeFromLibraryModalVisible, setRemoveFromLibraryModalVisible] = useState(false);
    const [selectedVersion, setSelectedVersion] = useState('');
    const [keyActivationModalVisible, setKeyActivationModalVisible] = useState(false);
    const [keyActivationModalValue, setKeyActivationModalValue] = useState("")
    const [changelogVisible, setChangelogVisible] = useState(false)
    const [verifyIntegrityModalVisible, setVerifyIntegrityModalVisible] = useState(false)

    // shoo the user if they are not on electron

    const handleVersionChange = (event) => {
        setSelectedVersion(event.target.value);
    };

    useEffect(() => {
        console.log(games[selectedGame])
    }, [selectedGame])

    useEffect(() => {
        if (!window.ipcRenderer) return
        console.log("!!!")
        refreshGameList(true)

        // listen for gameListUpdated events dispatched by window.dispatchEvent
        const GLU = () => {
            console.log("REFRESHING!!!")
            refreshGameList()
        }

        window.addEventListener("gameListUpdated", GLU)

        return () => {
            window.removeEventListener("gameListUpdated", GLU)
        }
    }, [])

    useEffect(() => {
        window.games = games
    }, [games])

    function formatSessionLength(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds - (hours * 3600)) / 60);
        const remainingSeconds = seconds - (hours * 3600) - (minutes * 60);

        let result = "";
        if (hours > 0) {
            result += `${hours}h `;
        }
        if (minutes > 0 || hours > 0) { // Include minutes if there are hours
            result += `${minutes}m `;
        }
        result += `${remainingSeconds.toFixed(0)}s`;

        return result.trim();
    }

    function refreshGameList(useCache = false) {
        console.log("REFRESHING!!! " + useCache)
        let waitCache = false
        /*if (useCache) {
            caches.open("request-cache").then((cache) => {
                cache.match("/LOCLIB").then((response) => {
                    if (response) {
                        response.json().then((data) => {
                            setGames(data)
                        })
                    }

                    waitCache = false
                })
            })
        }*/
        console.log(Date.now())
        window.ipcRenderer.invoke("getLocalGameList", { force: useCache === false }).then(async (data) => {
            console.log(data)
            console.log(Date.now())
            while (waitCache && useCache) {
                console.log("WAITING FOR CACHE")
                await new Promise((resolve) => setTimeout(resolve, 100))
            }

            // merge the local lib with the new fetched lib (also remove duplicates)

            let nG = games.map(game => {
                if (data.find(localGame => (localGame.game.appID) === game.game.appID)) return null

                return game
            })

            nG = nG.filter(game => game !== null)

            nG = nG.concat(data)

            // remove duplicates
            nG = nG.filter((game, index, self) =>
                index === self.findIndex((t) => (
                    t.game.appID === game.game.appID
                ))
            )

            console.log(nG)

            //setGames(nG)

            let localGame = nG[0]
            setSelectedGame(0)
            if (localGame?.isInstalled) {
                setIsGameInstalled(true)
                if (!localGame.game.thumbnail) {
                    setMigrationRequired(true)
                } else {
                    setMigrationRequired(false)
                    if (localGame.versions.find(ver => ver.versionID > localGame.version.versionID)) {
                        setIsGameUpdate(true)
                    } else {
                        setIsGameUpdate(false)
                    }
                }
            }

            util.postRequest("/api/user/getLibrary", {}, () => { }, "application/json", true).then((res) => {
                if (res.status === 200) {
                    let library = res.library.map(game => {
                        return {
                            game: game,
                            versions: game.versions,
                            version: {
                                ...game.versions[game.versions.length - 1],
                                thumbnail: game.thumbnail
                            },
                            folderSize: 0,
                            installedOn: "1970-01-01T00:00:00.000Z"
                        }
                    })

                    library.forEach(libraryGame => {
                        if (data.find(localGame => localGame.game.appID === libraryGame.game.appID)) {
                            // Update the versions of the existing game in data
                            /*data = data.map(localGame => {
                                if (localGame.game.appID === libraryGame.game.appID) {
                                    return localGame
                                } else {
                                    return libraryGame
                                }
                            })*/
                        } else {
                            // Add the game to data if it doesn't exist
                            data.push(libraryGame);
                        }
                    });

                    library = library.filter(game => game !== null)

                    library = library.filter((game, index, self) =>
                        index === self.findIndex((t) => (
                            t.game.appID === game.game.appID
                        ))
                    )

                    setGames(data)
                    setInstallInProgress(false)

                    setPlayTimes(res.playTimes)

                    localGame = data[0]
                    setSelectedGame(0)
                    if (localGame?.isInstalled) {
                        setIsGameInstalled(true)
                        if (!localGame.game.thumbnail) {
                            setMigrationRequired(true)
                        } else {
                            setMigrationRequired(false)
                            if (localGame.versions.find(ver => ver.versionID > localGame.version.versionID)) {
                                setIsGameUpdate(true)
                            } else {
                                setIsGameUpdate(false)
                            }
                        }
                    }

                    caches.open("request-cache").then((cache) => {
                        // store the library in the cache
                        cache.put("/LOCLIB", new Response(JSON.stringify(data.concat(library)), { status: 200 }))
                    })
                } else {
                    setGames(data)
                    setInstallInProgress(false)
                    console.log(data)
                    localGame = data[0]
                    setSelectedGame(0)
                    if (localGame) {
                        setIsGameInstalled(true)
                        if (!localGame.game.thumbnail) {
                            setMigrationRequired(true)
                        } else {
                            setMigrationRequired(false)
                            if (localGame.versions.find(ver => ver.versionID > localGame.version.versionID)) {
                                setIsGameUpdate(true)
                            } else {
                                setIsGameUpdate(false)
                            }
                        }
                    }

                    caches.open("request-cache").then((cache) => {
                        // store the library in the cache
                        cache.put("/LOCLIB", new Response(JSON.stringify(data), { status: 200 }))
                    })
                }
            }).catch((err) => {
                setGames(data)
                setInstallInProgress(false)

                caches.open("request-cache").then((cache) => {
                    // store the library in the cache
                    cache.put("/LOCLIB", new Response(JSON.stringify(data), { status: 200 }))
                })
                console.error(err)
            })
        })
    }

    if (!window.ipcRenderer) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "calc(100vh - 68.5px)",
                    flexDirection: "column"
                }}
            >
                <Typography
                    variant="h4"
                    gutterBottom
                >
                    I have a feeling you are not using the desktop app.
                </Typography>

                <Typography
                    variant="h4"
                >
                    Library is only available on the desktop app.
                </Typography>

                <Button
                    sx={{
                        mt: 1.5,
                        fontSize: "1.2rem"
                    }}
                    onClick={() => {
                        navigate("/download")
                    }}
                >
                    Download Desktop App
                </Button>
            </div>
        )
    }


    if (games.length === 0) return (<div
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 68.5px)",
            flexDirection: "column"
        }}
    >
        <Typography
            variant="h4"
        >
            No games found!
        </Typography>

        <Button
            sx={{
                mt: 1.5,
                fontSize: "1.2rem"
            }}
            onClick={() => {
                navigate("/")
            }}
        >
            Get Games!
        </Button>
    </div>)

    return (
        <div
            style={{
                display: "flex",
                height: "calc(100vh - 93px)",
                overflow: "hidden",
            }}
        >
            <Box
                style={{
                    width: "270px",
                    height: "100%",
                }}
            >
                <Box
                    style={{
                        width: "100%",
                        height: "70px",
                        //background: "linear-gradient(180deg, #121212 0%, #121212 60%, rgba(0,0,0,0) 100%)",
                        padding: 10,
                        paddingBottom: 0,
                        position: "relative",
                    }}
                >
                    <Typography
                        variant="h5"
                        sx={{
                            fontFamily: "Russo One",
                        }}
                    >
                        Library
                    </Typography>

                    <Tooltip title="Add Game By Key">
                        <IconButton
                            sx={{
                                position: "absolute",
                                right: 10,
                                top: 7,
                            }}
                            onClick={() => {
                                setKeyActivationModalVisible(true)
                                setKeyActivationModalValue("")
                            }}
                        >
                            <Add />
                        </IconButton>
                    </Tooltip>

                    <Dialog
                        open={keyActivationModalVisible}
                        onClose={() => setKeyActivationModalVisible(false)}
                    >
                        <DialogTitle>Activate Game</DialogTitle>

                        <DialogContent>
                            <Typography
                                variant="body1"
                            >
                                Enter the key you received to activate the game.
                            </Typography>

                            <Typography
                                variant="body2"
                                sx={{
                                    color: "#ee0000",
                                    fontWeight: "bold"
                                }}
                            >
                                Do not share your activation keys. Do not redistribute.
                            </Typography>

                            <TextField
                                autoFocus
                                margin="dense"
                                id="key"
                                label="Key"
                                placeholder="VCDE-XXXX-XXXX-XXXX-XXXX"
                                type="text"
                                fullWidth
                                value={keyActivationModalValue}
                                onChange={(e) => setKeyActivationModalValue(e.target.value)}
                                InputProps={{
                                    endAdornment: <Tooltip
                                        title="Paste from clipboard"
                                    ><IconButton
                                        onClick={() => {
                                            navigator.clipboard.readText().then(text => {
                                                setKeyActivationModalValue(text)
                                            })
                                        }}
                                    >
                                            <ContentPaste />
                                        </IconButton>
                                    </Tooltip>
                                }}
                            />
                        </DialogContent>

                        <DialogActions>
                            <Button
                                onClick={() => {
                                    setKeyActivationModalVisible(false)
                                }}
                            >
                                Cancel
                            </Button>

                            <Button
                                onClick={async () => {
                                    let res = await util.postRequest("/api/games/activateGame", { key: keyActivationModalValue })

                                    if (res.status === "ok") {
                                        snackCaller("Game activated successfully!")
                                        setKeyActivationModalVisible(false)
                                    }
                                }}
                            >
                                Activate
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>

                <Box
                    style={{
                        width: "100%",
                        height: "calc(100% - 60px)",
                        marginTop: -10
                    }}
                >
                    <Menu
                        open={contextMenu !== null}
                        onClose={() => {
                            setContextMenu(null);
                            setSelectedGame(0)

                            if (games[0]?.isInstalled) {
                                setIsGameInstalled(true)
                                const localGame = games[0]
                                if (localGame) {
                                    setIsGameInstalled(true)
                                    if (!localGame.game.thumbnail) {
                                        setMigrationRequired(true)
                                    } else {
                                        setMigrationRequired(false)
                                        if (localGame.versions.find(ver => ver.versionID > localGame.version.versionID)) {
                                            setIsGameUpdate(true)
                                        } else {
                                            setIsGameUpdate(false)
                                        }
                                    }
                                }
                            } else {
                                setIsGameInstalled(false)
                                setIsGameUpdate(false)
                            }



                        }}
                        anchorReference="anchorPosition"
                        anchorPosition={
                            contextMenu !== null
                                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                                : undefined
                        }
                    >
                        {games[selectedGame]?.isInstalled && <MenuItem
                            onClick={() => {
                                window.ipcRenderer.invoke("openFolder", { path: games[selectedGame].installPath })
                                setContextMenu(null)
                            }}
                        >
                            Browse Game Files
                        </MenuItem>}

                        <MenuItem
                            onClick={() => {
                                setContextMenu(null)
                                setRemoveFromLibraryModalVisible(true)
                            }}
                        >
                            Remove from Library
                        </MenuItem>
                    </Menu>

                    <Dialog
                        open={removeFromLibraryModalVisible}
                        onClose={() => setRemoveFromLibraryModalVisible(false)}
                    >
                        <DialogTitle>Remove {games[selectedGame]?.game?.name} from Library</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Are you sure you want to remove this game from your account library?
                                <br />
                                This action will not delete the game files from your computer.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    setRemoveFromLibraryModalVisible(false)
                                }
                                }>
                                Cancel
                            </Button>
                            <Button
                                onClick={async () => {
                                    util.postRequest("/api/games/removeFromLibrary", {
                                        appID: games[selectedGame]?.game?.appID
                                    })
                                }}
                            >
                                Remove
                            </Button>
                        </DialogActions>
                    </Dialog>


                    <Box
                        style={{
                            textAlign: "left",
                            height: "calc(100% - 20px)",
                            overflowY: "auto",
                        }}
                    >
                        {games.sort((a, b) => {
                            // sort by name, installed first then not installed
                            if (a?.isInstalled && !b?.isInstalled) return -1
                            if (!a?.isInstalled && b?.isInstalled) return 1
                            if (a?.game?.name < b?.game?.name) return -1
                            if (a?.game?.name > b?.game?.name) return 1

                            return 0
                        }).map((game, i) => {
                            if (!game?.version) {
                                console.log(game)
                                return <>
                                    <p>
                                        Game Corrupted. Please reinstall.
                                        {JSON.stringify(game)}
                                    </p>
                                </>
                            }
                            return (
                                <div
                                    key={`game${(game.appID || game.game?.appID) + (game.name?.slice(0, 1) || game.game?.name?.slice(0, 1))}`}
                                >
                                    <Button
                                        variant={i === selectedGame ? "outlined" : "text"}
                                        sx={{
                                            textAlign: "left",
                                            justifyContent: "flex-start",
                                            position: "relative",
                                            width: "100%",
                                            transition: "100ms",
                                            backdropFilter: (i === selectedGame ? "brightness(2)" : "brightness(1)") + " blur(2px)",
                                        }}
                                        onClick={() => {
                                            setSelectedGame(games.indexOf(game))
                                            console.log(game)
                                            if (game?.isInstalled) {
                                                setIsGameInstalled(true)
                                                const localGame = games[games.indexOf(game)]
                                                if (localGame) {
                                                    setIsGameInstalled(true)

                                                    if (!localGame.game.thumbnail) {
                                                        setMigrationRequired(true)
                                                    } else {
                                                        setMigrationRequired(false)
                                                        if (localGame.versions.find(ver => ver.versionID > localGame.version.versionID)) {
                                                            setIsGameUpdate(true)
                                                        } else {
                                                            setIsGameUpdate(false)
                                                        }
                                                    }
                                                }
                                            } else {
                                                setIsGameInstalled(false)
                                                setMigrationRequired(false)
                                                setIsGameUpdate(false)
                                            }
                                        }}
                                        onDoubleClick={() => {
                                            if (game?.isInstalled) {
                                                setIsGameInstalled(true)
                                                const localGame = games[games.indexOf(game)]
                                                if (localGame) {
                                                    setIsGameInstalled(true)

                                                    if (!localGame.game.thumbnail) {
                                                        setMigrationRequired(true)
                                                    } else {
                                                        setMigrationRequired(false)
                                                        if (localGame.versions.find(ver => ver.versionID > localGame.version.versionID)) {
                                                            setIsGameUpdate(true)
                                                        } else {
                                                            setIsGameUpdate(false)
                                                        }
                                                    }
                                                }
                                            } else {
                                                setIsGameInstalled(false)
                                                setMigrationRequired(false)
                                                setIsGameUpdate(false)
                                            }

                                            if (game?.isInstalled) {
                                                loadingCaller(true, games[selectedGame].game.name + " launching...")
                                                setTimeout(async () => {
                                                    loadingCaller(false)
                                                    let launchResult = await window.ipcRenderer.invoke("launchGame", { appID: parseInt(game?.game?.appID), versionID: parseInt(game?.version?.versionID) })
                                                    if (!launchResult) {
                                                        snackCaller("Failed to launch game")
                                                    }
                                                }, 500);

                                                if (game?.game?.versions.find(ver => ver.versionID > game?.version?.versionID)) {
                                                    setIsGameUpdate(true)
                                                } else {
                                                    setIsGameUpdate(false)
                                                }
                                            }

                                        }}

                                        onContextMenu={(event) => {
                                            event.preventDefault();
                                            setContextMenu({
                                                mouseX: event.clientX - 2,
                                                mouseY: event.clientY - 4,
                                            });

                                            setSelectedGame(games.indexOf(game))

                                            if (game?.isInstalled) {
                                                setIsGameInstalled(true)
                                                const localGame = games[games.indexOf(game)]
                                                if (localGame) {
                                                    setIsGameInstalled(true)

                                                    if (!localGame.game.thumbnail) {
                                                        setMigrationRequired(true)
                                                    } else {
                                                        setMigrationRequired(false)
                                                        if (localGame.versions.find(ver => ver.versionID > localGame.version.versionID)) {
                                                            setIsGameUpdate(true)
                                                        } else {
                                                            setIsGameUpdate(false)
                                                        }
                                                    }
                                                }
                                            } else {
                                                setIsGameInstalled(false)
                                                setMigrationRequired(false)
                                                setIsGameUpdate(false)
                                            }
                                        }}
                                    >
                                        <Image
                                            src={game.game.gameIcon?.url || game.game.gameIcon}
                                            style={{
                                                width: 20,
                                                height: 20,
                                                borderRadius: 2,
                                                marginRight: 10,
                                                objectFit: "cover",
                                            }}
                                        />
                                        <span
                                            style={{
                                                fontWeight: i === selectedGame ? "bold" : "normal",
                                                width: "calc(100% - 30px)",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                color: game?.isInstalled ? "inherit" : theme.palette.primary.main + "99",
                                            }}
                                        >
                                            {game.game.name}
                                        </span>
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 30
                                            }}
                                        >
                                            {!game.game.gameLibraryBanner && !game.game.repairNeeded && <Tooltip
                                                title="This game uses an older metadata version. Migration is required before playing."
                                            >
                                                <History
                                                    style={{ color: "#ee0000", position: "absolute", right: 6, bottom: 6 }}
                                                />
                                            </Tooltip>
                                            }

                                            {game.game.repairNeeded && <Tooltip
                                                title="This game needs repair. Please reinstall the game."
                                            >
                                                <Construction
                                                    style={{ color: "#ee0000", position: "absolute", right: 6, bottom: 6 }}
                                                />
                                            </Tooltip>
                                            }

                                            {(game.game.gameLibraryBanner && game?.isInstalled && game.game.versions.find(ver => ver.versionID > game.version?.versionID) == null) && <Tooltip
                                                title={`${game.game.name} is installed and ready to play!`}>
                                                <DownloadDone style={{ color: "#00ee00", position: "absolute", right: 6, bottom: 6 }} />
                                            </Tooltip>}
                                            {game.game.gameLibraryBanner && game.game.versions.find(ver => ver.versionID > game.version?.versionID) != null && <Tooltip
                                                title={`${game.game.name} has an update available!`}
                                            ><Sync sx={{
                                                color: "#4545ee", position: "absolute", right: 6, bottom: 6,
                                                // spin animation but
                                                animation: "spin 2s linear infinite",
                                                animationDirection: "reverse",
                                                transition: "200ms",
                                                "&:hover": {
                                                    // make the animation faster on hover
                                                    animationDuration: "1s",
                                                }
                                            }} /></Tooltip>}
                                            {() => {
                                                console.log(game)
                                            }}
                                        </ListItemIcon>
                                    </Button>
                                    {i === games.length - 1 ? <></> : <div
                                        style={{
                                            background: "linear-gradient(180deg, #121212 0%, #121212 60%, rgba(0,0,0,0) 100%)",
                                            width: "100%",
                                            height: 5,
                                        }}
                                    />}
                                </div>
                            )
                        })}
                    </Box>
                </Box>
            </Box>

            <Box
                style={{
                    width: "calc(100% - 270px)",
                    height: "calc(100vh - 94px)",
                    padding: 10,
                    position: "relative",
                    overflowY: "auto"
                }}
            >
                <Box
                    style={{
                        width: "100%",
                        aspectRatio: "96 / 31",
                        maxHeight: 550,
                        minHeight: 250,
                        borderRadius: 10,
                        overflow: "hidden",
                    }}
                    pointerEvents="none"
                >
                    <div
                        style={{
                            width: "100%",
                            aspectRatio: "96 / 31",
                            position: "relative",
                            maxHeight: 550,
                            minHeight: 250,
                        }}
                    >
                        <Image
                            src={games[selectedGame]?.game?.gameLibraryBanner?.url || games[selectedGame]?.game?.gameLibraryBanner}
                            style={{
                                height: "100%",
                                aspectRatio: "96 / 31",
                                objectFit: "cover",
                            }}
                        />
                        <div
                            style={{
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                background: "linear-gradient(0deg, #12121299 0%, rgba(0,0,0,0) 100%)",
                                top: 0,
                            }}
                        />

                        <Box
                            sx={{
                                position: "absolute",
                                bottom: 20,
                                left: 30,
                                color: "white",
                                display: "flex",
                                alignItems: "flex-end",
                                gap: 4,
                            }}
                        >
                            <Typography
                                variant="h4"
                                style={{
                                    fontFamily: "Montserrat",
                                    fontWeight: "bold",
                                }}
                            >
                                {games[selectedGame]?.game?.name}
                            </Typography>

                            <Typography
                                variant="h6"
                                style={{
                                    fontFamily: "Montserrat",
                                }}
                            >
                                Playtime: {formatSessionLength(playTimes.find(playTime => playTime.game?.appID === games[selectedGame]?.game?.appID)?.playTime / 1000 || 0)}
                            </Typography>
                        </Box>
                    </div>

                </Box>

                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            height: 125,
                            zIndex: 1,
                            //backgroundColor: "#121212",
                            borderBottomLeftRadius: 8,
                            borderBottomRightRadius: 8,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative"
                        }}
                    >
                        {migrationRequired &&
                            <Tooltip
                                title="Migration requires an active internet connection upon starting, please restart to migrate. If that doesn't work, this game may no longer public and you don't have access to the game details anymore."
                            ><Button
                                variant='contained'
                                color="error"
                                style={{
                                    position: "absolute",
                                    zIndex: 2,
                                    top: 35,
                                    left: 0,
                                    padding: 10, paddingRight: 20, paddingLeft: 20, fontSize: 20, fontWeight: "bold", color: "#fff",
                                    display: "flex",
                                    gap: 5
                                }}>
                                    MIGRATE
                                    <Update />
                                </Button>
                            </Tooltip>
                        }
                        {games[selectedGame]?.game?.repairNeeded && <Tooltip
                            title="This game needs repair. Please reinstall the game."
                        ><Button
                            variant='contained'
                            color="error"
                            onClick={async () => {
                                // try to find the game by it's name, we don't know anything else
                                // show the user something while we are doing this

                                loadingCaller(true, "Trying to repair " + games[selectedGame].game.name.split(" (Corrupted)")[0] + "...")

                                util.getRequest(`/api/games`, (res) => {
                                    let GamesList = res.games

                                    let game = GamesList.find(g => g.name === games[selectedGame].game.name.split(" (Corrupted)")[0])

                                    if (!game) {
                                        loadingCaller(false)
                                        snackCaller("Failed to repair game. Couldn't find the game in the game catalog.")
                                        return
                                    } else {
                                        setTimeout(() => {
                                            loadingCaller(true, "Found the game. Reinstalling...")

                                            setTimeout(() => {
                                                loadingCaller(false)
                                            }, 3000);

                                            window.ipcRenderer.invoke("installGame", { gameID: parseInt(game.appID), versionID: parseInt(game.versions[game.versions.length - 1].versionID), gameMeta: game }).then(async () => {
                                                loadingCaller(false)
                                                await window.ipcRenderer.invoke("getLocalGameList", { force: true }) // wait for any locks to be released
                                                refreshGameList()
                                            })
                                        }, 2000);
                                    }
                                })
                            }}
                            style={{
                                position: "absolute",
                                zIndex: 3,
                                top: 35,
                                left: 0,
                                padding: 10, paddingRight: 20, paddingLeft: 20, fontSize: 20, fontWeight: "bold", color: "#fff",
                                display: "flex",
                                gap: 5
                            }}>
                                REINSTALL
                                <Construction />
                            </Button>
                        </Tooltip>}

                        {!isGameInstalled ?
                            <Button disabled={games[selectedGame].game.underReview && false || installInProgress} style={{ padding: 10, paddingRight: 20, paddingLeft: 20, fontSize: 20, fontWeight: "bold", color: "#fff" }} variant="contained" color="success" onClick={async () => {

                                let curIndex = selectedGame
                                setInstallInProgress(true)
                                await window.ipcRenderer.invoke("installGame", { gameID: parseInt(games[selectedGame].game.appID), versionID: parseInt(games[selectedGame].versions[games[selectedGame].versions.length - 1].versionID), gameMeta: games[selectedGame] })
                                setInstallInProgress(false)
                                await window.ipcRenderer.invoke("getLocalGameList", { force: true }) // wait for anly locks to be released

                                refreshGameList()
                            }}><Download style={{ marginRight: 10 }} />{installInProgress ? "Downloading..." : "Download"}</Button>
                            :
                            isGameUpdate ?
                                <div style={{ display: "flex" }}>
                                    <Button disabled={games[selectedGame].game.underReview || installInProgress} style={{ padding: 10, fontSize: 20, fontWeight: "bold", color: "#fff", borderTopRightRadius: 0, borderBottomRightRadius: 0, paddingRight: 17 }} variant="contained" color="success" onClick={async () => {

                                        setInstallInProgress(true)
                                        await window.ipcRenderer.invoke("installGame", { gameID: parseInt(games[selectedGame].game.appID), versionID: parseInt(games[selectedGame].versions[games[selectedGame].versions.length - 1].versionID), gameMeta: games[selectedGame] })
                                        setInstallInProgress(false)
                                        await window.ipcRenderer.invoke("getLocalGameList", { force: true }) // wait for anly locks to be released

                                        refreshGameList()
                                    }}><Download style={{ marginRight: 10 }} />{installInProgress ? "Downloading..." : "Update"}</Button>
                                    {!requiredUpdate && <Button style={{ padding: 10, fontSize: 20, fontWeight: "bold", color: "#fff", borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} variant="contained" color="success" onClick={() => {

                                        setTimeout(async () => {
                                            let launchResult = await window.ipcRenderer.invoke("launchGame", { appID: parseInt(games[selectedGame].game.appID), versionID: parseInt(games[selectedGame].version.versionID) })
                                            if (!launchResult) {
                                                snackCaller("Failed to launch game")
                                            }
                                        }
                                            , 500);
                                    }}><PlayArrow style={{ marginRight: 10 }} />Play</Button>}
                                </div>
                                :
                                <Button style={{ padding: 10, fontSize: 20, fontWeight: "bold", color: "#fff" }} variant="contained" color="success" onClick={() => {
                                    loadingCaller(true, games[selectedGame].game.name + " launching...")

                                    setTimeout(async () => {
                                        let launchResult = await window.ipcRenderer.invoke("launchGame", { appID: parseInt(games[selectedGame].game.appID), versionID: parseInt(games[selectedGame].version.versionID) })

                                        loadingCaller(false)
                                        if (!launchResult) {
                                            snackCaller("Failed to launch game")
                                        }
                                    }, 500);
                                }}><PlayArrow style={{ marginRight: 10 }} />Play</Button>}

                        <div
                            style={{
                                width: "100%",
                                height: 55,
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                            }}
                        >
                            <Dialog
                                open={infoModalVisible}
                                onClose={() => setInfoModalVisible(false)}
                            >
                                <DialogTitle>{games[selectedGame]?.game?.name}</DialogTitle>

                                <DialogContent>
                                    <DialogContentText>
                                        Installed Version: {games[selectedGame]?.version?.version} (Version ID: {games[selectedGame]?.version?.versionID})
                                        <br />
                                        Version Release: {new Date(games[selectedGame]?.version?.created).toLocaleString()}
                                        <br />
                                        Installed On: {new Date(games[selectedGame]?.installedOn).toLocaleString()}
                                        <br />
                                        Install Size: {Math.trunc(games[selectedGame]?.folderSize / 1024 / 1024)} MB
                                        {games[selectedGame]?.game?.underReview && <span style={{ color: "#ee0000", fontWeight: "bold" }}><br />Under Review</span>}
                                        {isGameInstalled && isGameUpdate && <span style={{ color: "#ee0000", fontWeight: "bold" }}><br />New update available!</span>}
                                    </DialogContentText>
                                </DialogContent>

                                <DialogActions>
                                    <Button
                                        onClick={() => {
                                            setInfoModalVisible(false)
                                        }}
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Button
                                variant='outlined'
                                sx={{
                                    mr: 2,
                                }}
                                onClick={() => {
                                    console.log(games[selectedGame])
                                    navigate("/games/" + games[selectedGame]?.game?.appID)
                                }}
                            >
                                Game Page
                            </Button>
                            <Tooltip title={"Changelog"} arrow>
                                <IconButton onClick={() => {
                                    setChangelogVisible(true)
                                }}><HistoryOutlined /></IconButton>
                            </Tooltip>

                            <Dialog
                                open={changelogVisible}
                                onClose={() => setChangelogVisible(false)}
                                fullWidth
                            >
                                <DialogTitle
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}
                                >
                                    Changelog
                                    <IconButton onClick={() => setChangelogVisible(false)}><CloseOutlined /></IconButton>
                                </DialogTitle>

                                <DialogContent>
                                    <Markdown
                                        markdown={
                                            [...games[selectedGame].versions].reverse().map((v, i) => {
                                                return `
## Version ${v.releaseName} - ${new Date(v.created).toLocaleDateString(undefined, { day: "numeric", month: "short", year: "numeric" })}

${(v.changelog === "Initial release" && i !== games[selectedGame].versions.length - 1) ? "No changelog provided." : v.changelog}
                                                    `
                                            }).join("\n\n")
                                        }
                                    />
                                </DialogContent>
                            </Dialog>

                            <Tooltip title="Report Bugs">
                                <IconButton
                                    onClick={() => {
                                        callBugReportModal(games[selectedGame]?.game?.name, games[selectedGame]?.game?.appID)
                                    }}
                                    sx={{

                                    }}
                                >
                                    <BugReport />
                                </IconButton>
                            </Tooltip>


                            <Tooltip title="Verify Integrity">
                                <IconButton
                                    onClick={() => {
                                        setVerifyIntegrityModalVisible(true)
                                    }}
                                    sx={{
                                        display: isGameInstalled ? "box" : "none"
                                    }}
                                >
                                    <Task />
                                </IconButton>
                            </Tooltip>

                            <Dialog
                                open={verifyIntegrityModalVisible}
                                onClose={() => setVerifyIntegrityModalVisible(false)}
                            >
                                <DialogTitle>Verify Integrity</DialogTitle>

                                <DialogContent>
                                    <Typography
                                        sx={{
                                            
                                        }}
                                    >
                                        Are you sure you want to verify the integrity of this game?
                                        <br />
                                        This action will download the whole game again and replace any corrupted files.
                                        <br /><br />

                                        <strong>This might take a while!</strong>
                                    </Typography>
                                </DialogContent>

                                <DialogActions>
                                    <Button
                                        variant='outlined'
                                        onClick={() => {
                                            setVerifyIntegrityModalVisible(false)
                                        }}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        variant='contained'
                                        onClick={async () => {
                                            setVerifyIntegrityModalVisible(false)
                                            snackCaller("Verifying integrity of " + games[selectedGame]?.game?.name + "... You can manage the process in the downloads pane.")
                                            await window.ipcRenderer.invoke("installGame", { gameID: parseInt(games[selectedGame].game.appID), versionID: parseInt(games[selectedGame].version.versionID), gameMeta: games[selectedGame], verify: true })
                                        }}
                                    >
                                        Verify
                                    </Button>

                                </DialogActions>
                            </Dialog>


                            <Tooltip title="Info">
                                <IconButton
                                    onClick={() => {
                                        setInfoModalVisible(true)
                                    }}
                                    sx={{
                                        display: isGameInstalled ? "box" : "none",
                                    }}
                                >
                                    <Info />
                                </IconButton>
                            </Tooltip>

                            <Dialog
                                open={settingsModalVisible}
                                onClose={() => setSettingsModalVisible(false)}
                            >
                                <DialogTitle>Manage {games[selectedGame]?.game?.name}</DialogTitle>

                                <DialogContent>
                                    <InputLabel id="version-label">Select Version to install. Currently Installed: {isGameInstalled ? games[selectedGame]?.version?.version : "NONE"} (v{isGameInstalled ? games[selectedGame]?.version?.versionID : 0})</InputLabel>
                                    <Select
                                        labelId="version-label"
                                        id="version-select"
                                        value={selectedVersion}
                                        onChange={handleVersionChange}
                                        sx={{ mb: 2 }}
                                    >
                                        {[...games[selectedGame]?.versions]?.reverse()?.map((version) => {
                                            return (
                                                <MenuItem disabled={!version.isHardUpdate} value={version.versionID} sx={{
                                                    fontWeight: version.isHardUpdate ? "bolder" : "normal"
                                                }}>{version.version} (v{version.versionID}){version.isHardUpdate ? "*" : ""} - {new Date(version.created).toLocaleDateString()}</MenuItem>
                                            )
                                        })}
                                    </Select>

                                    <DialogContentText>
                                        You can install any version marked with an asterisk (*) to change to a different version of the game. Using this feature may cause issues with the game. Use with caution.
                                    </DialogContentText>

                                    <DialogActions>
                                        <Button
                                            onClick={() => {
                                                setSettingsModalVisible(false)
                                            }}
                                        >
                                            Cancel
                                        </Button>

                                        <Button
                                            disabled={selectedVersion === games[selectedGame]?.version?.versionID && games[selectedGame]?.versions.find(version => version?.versionID === selectedVersion)?.isHardUpdate && isGameInstalled === true}
                                            onClick={async () => {
                                                setSettingsModalVisible(false)

                                                let modifiedData = { ...games[selectedGame], version: games[selectedGame].versions.find(version => version.versionID === selectedVersion) }

                                                // check if the target version is the latest hard update, so we can enable auto updates again
                                                if (games[selectedGame]?.versions.find(version => version?.versionID === selectedVersion)?.isHardUpdate) {
                                                    await window.ipcRenderer.invoke("installGame", { gameID: games[selectedGame].game.appID, versionID: selectedVersion, gameMeta: modifiedData })
                                                    refreshGameList()
                                                    return
                                                }

                                                await window.ipcRenderer.invoke("installGame", { gameID: games[selectedGame].game.appID, versionID: selectedVersion, gameMeta: modifiedData, hold: true })

                                                refreshGameList()
                                            }}
                                        >
                                            Install
                                        </Button>
                                    </DialogActions>
                                </DialogContent>
                            </Dialog>

                            {false && <Tooltip title="Settings">
                                <IconButton
                                    onClick={() => {
                                        setSelectedVersion(games[selectedGame]?.version?.versionID)
                                        setSettingsModalVisible(true)
                                    }}
                                >
                                    <Settings />
                                </IconButton>
                            </Tooltip>}

                            <Dialog
                                open={deleteModalVisible}
                                onClose={() => setDeleteModalVisible(false)}
                            >
                                <DialogTitle>Delete {games[selectedGame]?.game?.name}</DialogTitle>

                                <DialogContent>
                                    <DialogContentText>
                                        Are you sure you want to delete this game?
                                        <br />
                                        This action cannot be undone.
                                    </DialogContentText>
                                </DialogContent>

                                <DialogActions>
                                    <Button
                                        onClick={() => {
                                            setDeleteModalVisible(false)
                                        }}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        onClick={async () => {
                                            await window.ipcRenderer.invoke("uninstallGame", { appID: parseInt(games[selectedGame]?.game?.appID) })
                                            refreshGameList()
                                            setDeleteModalVisible(false)
                                            setIsGameInstalled(false)
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <IconButton
                                onClick={() => {
                                    setDeleteModalVisible(true)
                                }}
                                sx={{
                                    display: isGameInstalled ? "box" : "none"
                                }}
                            >
                                <Delete />
                            </IconButton>
                        </div>
                    </Box>
                    <Box
                        style={{
                            padding: 10,
                            paddingTop: 3,
                            height: "calc(100% - 125px)",
                            overflowY: "auto",
                            textAlign: "left"
                        }}
                    >
                        <Markdown
                            markdown={"## What's new:" +
                                "\n\n\n" +
                                games[selectedGame]?.versions[games[selectedGame]?.versions?.length - 1]?.changelog +
                                "\n\n\n" +
                                "## Description:" +
                                "\n\n\n" +
                                games[selectedGame]?.game?.description
                            }
                            style={{
                                width: "50%",
                                wordBreak: "break-all",
                                height: 300
                            }}
                        />
                    </Box>
                </Box>


            </Box>
        </div>
    )
};

export default Home;