import { Typography, Box, Dialog, DialogTitle, DialogContent, FormControlLabel, Checkbox, FormGroup, DialogActions, FormControl, TextField, MenuItem, ButtonBase, Autocomplete, createFilterOptions, Table, TableContainer, Paper, TableHead, TableRow, TableCell, TableBody, DialogContentText, Chip, Tooltip, Select, InputAdornment, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import { useEffect, useState, Component } from 'react';
import { useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Util, { isElectron, wrapperForMySanity } from '../../util';
import AnalyticsRoot from "../GameAnalytics/analyticsRoot"
import Image from '../../components/Image';
import { Add, Casino, Close, ContentCopy, CopyAll, Info, InfoOutlined, OpenInNew, PlayArrow, Warning } from '@mui/icons-material';
import { callLoadingLine, loadingCaller, navigate, snackCaller } from "../../App"
import MarkdownEditor from '../../components/MarkdownEditor';
import TextWithTooltip from '../../components/TextWithTooltip';
import Markdown from '../../components/Markdown';

const isURL = Util.isURL
const filter = createFilterOptions()

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    const [hasRendered, setHasRendered] = useState(false);

    if (value === index && !hasRendered) {
        setHasRendered(true);
    }

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {hasRendered && (
                <Box sx={{ display: value === index ? 'block' : 'none' }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const changelogGuide = `***This guide will disappear when you start typing!***
# Writing a changelog

Changelogs are a way to communicate the changes you've made to your game to your players. It's a good way to keep your players informed about what's new, what's changed, and what's fixed.

## What to include in a changelog

- **New features**: Describe any new features you've added to your game.
- **Changes**: Describe any changes you've made to existing features.
- **Bug fixes**: Describe any bugs you've fixed.
- **Performance improvements**: Describe any performance improvements you've made.
- **Balancing changes**: Describe any changes you've made to the game's balance.
- **Quality of life improvements**: Describe any changes you've made to improve the player experience.
- **Other changes**: Describe any other changes you've made that you think are important for your players to know about.

## Other tips

- **Versioning**: Using [semantic versioning](https://semver.org/) may help you keep track of your game's versions.
- **Formatting**: You can use [Markdown](https://www.markdownguide.org/) to format your changelog.


***Or you can always just write a simple changelog like "Bug fixes and performance improvements", we all do it once in a while, no?***`
/**
 * 
 * CURRENT---
 * 
 * Version Selector
 * 
 * Binary Selection (Select from Binary Depot -- NEW)
 * Current Version Selector
 * Required Update Selector
 * Delist Option for game itself
 * 
 * Short Description
 * Tags
 * Support Page
 * Discord Invite
 * Changelog (EDIT ONLY)
 * 
 * Thumbnail
 * Game Screenshots/Videos
 * Description
 * 
 * NEW---
 * 
 * Binary Uploading without pushing update
 * Assigning Binaries to Versions
 * Exclusive Versions
 * Drag and Drop Media Sorting
 * Game Library Icon
 * Game Library Banner
 * 
 */

/**
 * BASIC DETAILS
 * 
 * Game Title X
 * Game Platform (Windows, Linux, Web) X
 * Acknowledgements (CREATE ONLY) X
 * Support Link X
 * Discord Invite X
 * Game Visibility X
 * 
 * GAME DETAILS
 * 
 * Short Description X
 * Game Page Image/Video -
 * Game Icon X
 * Game Thumbnail X
 * Game Library Banner X
 * 
 * 
 * DESCRIPTION
 * 
 * Description
 * Tags
 * 
 * RELEASES
 * 
 * Push New Release
 * - Version Name
 * - Changelog
 * - Update Necessity
 * - Binary Selection from Depot
 * - Exclusivity Option
 * 
 * View All Releases
 * 
 * BINARY DEPOT
 * Upload New Binary
 * List of Binaries
 * 
 */

function AutoMediaElement(props) {
    const [isVideo, setIsVideo] = useState(false)
    const [retries, setRetries] = useState(0)

    useEffect(() => {
        setRetries(0)
    }, [props.url])

    if (retries > 3) {
        return (
            <Typography>err</Typography>
        )
    }

    if (isVideo) {
        return (
            <div
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
            >
                <video style={{ width: "100%", height: "100%", objectFit: "cover" }} muted loop onError={() => {
                    if (retries < 3) {
                        setRetries(retries + 1)
                        setIsVideo(false)
                        return;
                    }
                }}
                    src={props.url}
                />
                <PlayArrow
                    sx={{
                        position: "absolute",
                        fontSize: 32,
                        top: 10,
                        left: 10,
                        backdropFilter: "blur(5px)",
                        backgroundColor: "#00000045"
                    }}
                />
            </div>
        )
    }

    if (!isVideo) {
        return (
            <Image
                src={props.url}
                style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                }}
                eCallback={() => {
                    if (retries < 3) {
                        setRetries(retries + 1)
                        setIsVideo(true)
                        return;
                    }

                }}
            />
        )
    }
}

class MediaPane extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isThumbnail: this.props.isThumbnail,
            isVideo: this.props.media ? this.props.media.endsWith(".mp4") : false,
            hover: false,
            aspectRatio: this.props.aspectRatio || "16 / 9",
            width: this.props.width || 320,
            media: this.props.media || "",
            isDragging: false,
            isDropping: false
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.media !== prevState.media || nextProps.isVideo !== prevState.isVideo) {
            return {
                media: nextProps.media,
                isVideo: nextProps.media?.startsWith(".mp4") || nextProps.isVideo || false,
            };
        }
        return null;
    }

    handleMouseEnter = () => {
        this.setState({ hover: true });
    };

    handleMouseLeave = () => {
        this.setState({ hover: false });
    };

    render() {
        const { media } = this.props
        const isVideo = this.state.isVideo

        // get if its a video by checking its mime, it may not end with mp4 as it can be a blob URL, so we need to check the mime
        return (
            <Box
                draggable={!this.state.isThumbnail}
                onDragStart={(event) => {
                    if (this.state.isThumbnail) {
                        event.preventDefault();
                        return;
                    }

                    const dragData = {
                        media: media,
                        id: this.props.id,
                    };
                    event.dataTransfer.setData(
                        "application/json",
                        JSON.stringify(dragData)
                    );
                    event.dataTransfer.effectAllowed = "move";
                    this.setState({ isDragging: true });
                }}
                onDragEnd={() => {
                    this.setState({ isDragging: false });
                }}
                onDragOver={(event) => {
                    event.preventDefault();
                    if (this.state.isDragging || this.state.isThumbnail) return;
                }}
                onDragEnter={(event) => {
                    event.preventDefault();
                    if (this.state.isDragging || this.state.isThumbnail) return;
                    this.setState({ isDropping: true });
                }}
                onDragLeave={(event) => {
                    event.preventDefault();
                    if (this.state.isDragging || this.state.isThumbnail) return;
                    this.setState({ isDropping: false });
                }}

                onDrop={(ev) => {
                    ev.preventDefault();
                    if (this.state.isDragging || this.state.isThumbnail) return;
                    const data = JSON.parse(
                        ev.dataTransfer.getData("application/json")
                    );
                    const draggedId = data.id;

                    // Swap media items
                    let newMedia = [...this.props.that.state.gameDetails.gamePageMedia];
                    [newMedia[this.props.id], newMedia[draggedId]] = [
                        newMedia[draggedId],
                        newMedia[this.props.id],
                    ];

                    let newScratchList = [...this.props.that.state.gameDetails.scratchList];
                    [newScratchList[this.props.id], newScratchList[draggedId]] = [
                        newScratchList[draggedId],
                        newScratchList[this.props.id],
                    ];

                    this.setState({ isDragging: false, isDropping: false });
                    this.props.that.setState({
                        gameDetails: {
                            ...this.props.that.state.gameDetails,
                            gamePageMedia: newMedia,
                            scratchList: newScratchList,
                        },
                    });
                }}
                sx={{
                    backgroundColor: "#202020",
                    borderRadius: 2,
                    overflow: "hidden",
                    aspectRatio: this.state.aspectRatio,
                    width: this.state.width,
                    minWidth: this.state.width,
                    textAlign: "center",
                    position: "relative",
                    transition: "background-color 0.3s ease-in-out",
                    "&:hover": {
                        backgroundColor: "#303030",
                    },
                }}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
            >
                <Box
                    sx={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        zIndex: 500,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        transition: "0.3s ease-in-out",
                        backgroundColor: this.state.isDropping
                            ? "rgba(48, 48, 48, 0.3)"
                            : "transparent",
                        pointerEvents: "none",
                        backdropFilter: this.state.isDropping ? "blur(5px)" : "none",
                    }}
                >
                    <Typography
                        sx={{
                            color: "#fff",
                            fontFamily: "Montserrat",
                            fontSize: 20,
                            fontWeight: "bold",
                            opacity: this.state.isDropping ? 1 : 0,
                            transition: "opacity 0.3s ease-in-out",
                            pointerEvents: "none",
                        }}
                    >
                        Drop Here
                    </Typography>
                </Box>
                {!media && (
                    <Box
                        sx={{
                            overflow: "hidden",
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: "Montserrat",
                                mt: 3.5,
                            }}
                            variant="h6"
                        >
                            No {this.state.isThumbnail ? "Thumbnail" : "Media"} Selected
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: "Montserrat",
                                position: "absolute",
                                opacity: 0.5,
                                bottom: 10,
                                right: 20,
                            }}
                            variant="h6"
                        >
                            {this.state.aspectRatio.replace(" / ", ":")}
                        </Typography>

                        <Add
                            sx={{
                                fontSize: 80,
                                transition: "transform 0.3s ease-in-out",
                                transform: this.state.hover ? "rotate(-15deg)" : "rotate(0)",
                            }}
                        />

                        <Typography
                            style={{
                                position: "absolute",
                                bottom: 0,
                                transform: this.state.hover
                                    ? "translateY(-10%)"
                                    : "translateY(100%)",
                                transition: "transform 0.3s ease-in-out",
                                color: "#fff",
                                width: "100%",
                                textAlign: "center",
                                padding: "10px 0",
                                fontSize: 20,
                                fontFamily: "Montserrat",
                                fontWeight: "bold",
                            }}
                        >
                            Add Media
                        </Typography>
                    </Box>
                )}


                {media && (
                    <Box
                        sx={{
                            width: "100%",
                            aspectRatio: this.state.aspectRatio,
                            position: "relative",
                        }}
                    >
                        <AutoMediaElement url={media} />
                    </Box>
                )}

                <ButtonBase
                    sx={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        zIndex: 2,
                        top: 0,
                        left: 0,
                    }}
                    onClick={() => {
                        loadingCaller(true, "Selecting Media...")

                        try {
                            window.ipcRenderer
                                .invoke("selectFile", {
                                    extensions: ["png", "jpg", "jpeg", "webp", "mp4"],
                                    base64: true,
                                    singleFile: true,
                                })
                                .then((res) => {
                                    console.log(`Selected ${res?.filePath}`)
                                    if (!res?.filePath) return loadingCaller(false)
                                    if (res.filePath.endsWith(".mp4")) {
                                        // get it optimized
                                        window.ipcRenderer.invoke("optimizeVideo", {
                                            filePath: res.filePath
                                        }).then((b64) => {
                                            if (!b64) return loadingCaller(false)
                                            let base64 = Util.base64ToBlob(b64);

                                            this.setState(
                                                {
                                                    media: base64,
                                                    isVideo: true,
                                                },
                                                () => {
                                                    if (this.state.isThumbnail) {
                                                        this.props.that.setState({
                                                            gameDetails: {
                                                                ...this.props.that.state.gameDetails,
                                                                thumbnail: base64,
                                                            },
                                                        });
                                                    } else {
                                                        this.props.that.setState({
                                                            gameDetails: {
                                                                ...this.props.that.state.gameDetails,
                                                                gamePageMedia: this.props.that.state.gameDetails.gamePageMedia.map(
                                                                    (_, i) => {
                                                                        if (i === this.props.id)
                                                                            return base64;
                                                                        return _;
                                                                    }
                                                                ),
                                                                scratchList: this.props.that.state.gameDetails.scratchList.map(
                                                                    (_, i) => {
                                                                        if (i === this.props.id)
                                                                            return {
                                                                                fileName: res.filePath.split("/").pop(),
                                                                                filePath: res.filePath,
                                                                            };
                                                                        return _;
                                                                    }
                                                                ),
                                                            },
                                                        });
                                                    }
                                                }
                                            );

                                            loadingCaller(false)
                                        })
                                    } else {
                                        window.ipcRenderer
                                            .invoke("optimizeWithWebP", {
                                                filePath: res.filePath,
                                            })
                                            .then((b64) => {
                                                b64 = Util.base64ToBlob(b64);

                                                this.setState(
                                                    {
                                                        media: b64,
                                                        isVideo: false,
                                                    },
                                                    () => {
                                                        if (this.props.isThumbnail) {
                                                            this.props.that.setState({
                                                                gameDetails: {
                                                                    ...this.props.that.state.gameDetails,
                                                                    thumbnail: b64,
                                                                },
                                                            });
                                                        } else {
                                                            console.log("setting image on ", this.props.id)
                                                            this.props.that.setState({
                                                                gameDetails: {
                                                                    ...this.props.that.state.gameDetails,
                                                                    gamePageMedia: this.props.that.state.gameDetails.gamePageMedia.map(
                                                                        (_, i) => {
                                                                            if (i === this.props.id)
                                                                                return b64;
                                                                            return _;
                                                                        }
                                                                    ),
                                                                    scratchList: this.props.that.state.gameDetails.scratchList.map(
                                                                        (_, i) => {
                                                                            if (i === this.props.id)
                                                                                return {
                                                                                    fileName: res.filePath.split("/").pop(),
                                                                                    filePath: res.filePath,
                                                                                };
                                                                            return _;
                                                                        }
                                                                    ),
                                                                },
                                                            });
                                                        }

                                                        loadingCaller(false)
                                                    }
                                                );
                                            });
                                    }
                                });
                        } catch (e) {
                            loadingCaller(false)
                        }

                    }}
                />

                {media && (
                    <ButtonBase
                        sx={{
                            position: "absolute",
                            zIndex: 3,
                            top: 0,
                            right: 0,
                            background: "rgba(0, 0, 0, 0.5)",
                            backdropFilter: "blur(5px)",
                            width: 32,
                            height: 32,
                            borderBottomLeftRadius: 4,
                        }}
                        onClick={() => {
                            if (this.state.isThumbnail) {
                                this.props.that.setState({
                                    gameDetails: {
                                        ...this.props.that.state.gameDetails,
                                        thumbnail: null,
                                    },
                                });
                            } else {
                                this.props.that.setState({
                                    gameDetails: {
                                        ...this.props.that.state.gameDetails,
                                        gamePageMedia: [...this.props.that.state.gameDetails.gamePageMedia.filter(
                                            (_, i) => {
                                                return i !== this.props.id;
                                            }
                                        ), ""],
                                    },
                                });
                            }

                            // Cleanup data URL
                            if (!media.startsWith("http"))
                                window.URL.revokeObjectURL(media);

                            this.setState({
                                media: null,
                                isVideo: false,
                            });
                        }}
                    >
                        <Close />
                    </ButtonBase>
                )}
            </Box>
        );
    }
}

export default class GameManager extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isNewGame: Util.returnCurrentDynamicURL()[2] === "",
            page: 0,
            isAcknowledgementsVisible: Util.returnCurrentDynamicURL()[2] === "",
            basicDetails: {
                name: "",
                platform: "",
                supportPage: "",
                discordInvite: "",
                delisted: false
            },
            gameDetails: {
                shortDescription: "",
                gameIcon: "",
                gameLibraryBanner: "",
                gamePageMedia: [],
                scratchList: [],
                thumbnail: "",
            },
            description: {
                description: "",
                tags: []
            },
            checkboxes: {
                authorized: false,
                terms: false,
                malware: false,
            },
            underReview: false,
            binaryDepot: [],
            actionButton: false,
            tagOptions: [],
            binarySelectionDialogVisible: false,
            executablePath: "",
            executableComment: "",
            preCompressSize: 0,
            estimatedCompressSize: 0,
            binary: "",
            releases: [],
            versionCreationDialogVisible: false,
            release: {
                versionName: "",
                isExclusive: false,
                changelog: null,
                binary: "-1",
                key: "",
                releaseAfter: new Date("1970-01-01T00:00:00Z"),
            },
            guidePrompt: false,
        }

        this.fetchData()
        if (!isElectron()) navigate("/");
    }

    fetchData() {
        if (!this.state.isNewGame) {
            // fetch game edit data

            Util.postRequest("/api/games/getNewGameEditData", {
                appID: Util.returnCurrentDynamicURL()[2]
            }).then((res) => {
                if (!res.commonData) {
                    // TODO handle error

                    return;
                }

                // make the res.commonData.media an array with a length of 10
                let med = res.commonData.media
                while (med.length < 10) {
                    med.push("")
                }

                Util.getRequest("/api/games").then((g) => {
                    let user = JSON.parse(localStorage.getItem("user"));
                    let myGame = g.games.filter((game) => {
                        return game.developer.UUID === user.UUID;
                    })

                    this.setState({
                        basicDetails: {
                            ...this.state.basicDetails,
                            name: res.commonData.name,
                            platform: res.commonData.gamePlatform,
                            supportPage: res.commonData.supportPage,
                            discordInvite: res.commonData.discordInvite,
                            delisted: res.commonData.delisted
                        },
                        gameDetails: {
                            ...this.state.gameDetails,
                            thumbnail: res.commonData.thumbnail,
                            shortDescription: res.commonData.shortDescription,
                            gameIcon: res.commonData.gameIcon || "",
                            gameLibraryBanner: res.commonData.gameLibraryBanner || res.commonData.thumbnail || res.commonData.media[0] || "",
                            gamePageMedia: med,
                            scratchList: new Array(10).fill(""),
                        },
                        description: {
                            description: res.commonData.description,
                            tags: res.commonData.tags
                        },
                        releases: res.releases.reverse(),
                        binaryDepot: res.depots.reverse(),
                        underReview: res.commonData.underReview,
                        guidePrompt: myGame.length === 0
                    }, () => {
                        this.checkValidity();
                    })
                })
            })
        }
    }

    componentWillUnmount() {
        // cleanup all blobs so no memory leaks
        [...this.state.gameDetails.gamePageMedia, this.state.gameDetails.thumbnail, this.state.gameDetails.gameIcon].forEach(u => {
            if (u?.startsWith("blob")) {
                URL.revokeObjectURL(u)
                console.log("Revoked ", u)
            }
        })
    }

    checkValidity() {
        let s = (() => {
            if (this.state.isAcknowledgementsVisible) {
                return this.state.checkboxes.terms && this.state.checkboxes.authorized && this.state.checkboxes.malware;
            }

            if (this.state.page === 0) {
                if (this.state.basicDetails.name?.length < 2) return false;
                if (this.state.basicDetails.platform?.length < 2) return false;
                if (this.state.basicDetails.supportPage?.length > 0 && !isURL(this.state.basicDetails.supportPage)) return false;
                if (this.state.basicDetails.discordInvite?.length > 0 && !isURL(this.state.basicDetails.discordInvite)) return false


                return true;
            }

            return true // NOTE REMOVE THIS
        })

        this.setState({
            actionButton: s()
        })
    }

    render() {
        const handleChange = (event, newValue) => {
            this.setState({ page: newValue });
        };

        return (
            <Box style={{ marginTop: 0, margin: 0, maxWidth: "xs" }}>
                {this.state.isAcknowledgementsVisible && <Dialog
                    open={this.state.isAcknowledgementsVisible}
                    onClose={() => {

                    }}
                >
                    <DialogTitle>
                        Please acknowledge and agree to the following terms before continuing.
                    </DialogTitle>

                    <DialogContent>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={this.state.checkboxes.terms} onChange={(ev) => {
                                this.setState({
                                    checkboxes: {
                                        authorized: this.state.checkboxes.authorized,
                                        terms: ev.target.checked,
                                        malware: this.state.checkboxes.malware
                                    }
                                }, () => {
                                    this.checkValidity()
                                });
                            }} />} label="I have read and agree to the terms of service" />

                            <FormControlLabel control={<Checkbox checked={this.state.checkboxes.authorized} onChange={(ev) => {
                                this.setState({
                                    checkboxes: {
                                        authorized: ev.target.checked,
                                        terms: this.state.checkboxes.terms,
                                        malware: this.state.checkboxes.malware
                                    }
                                }, () => {
                                    this.checkValidity()
                                });
                            }} />} label="I am authorized to upload this game" />

                            <FormControlLabel control={<Checkbox checked={this.state.checkboxes.malware} onChange={(ev) => {
                                this.setState({
                                    checkboxes: {
                                        authorized: this.state.checkboxes.authorized,
                                        terms: this.state.checkboxes.terms,
                                        malware: ev.target.checked
                                    }
                                }, () => {
                                    this.checkValidity()
                                });
                            }} />} label="I am not uploading malware" />
                        </FormGroup>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            disabled={!this.state.actionButton}
                            onClick={() => {
                                this.setState({ isAcknowledgementsVisible: false });
                            }}>Continue</Button>

                        <Button onClick={() => {
                            this.setState({ isAcknowledgementsVisible: false });
                        }}>Cancel</Button>
                    </DialogActions>
                </Dialog>}

                <Dialog
                    open={this.state.guidePrompt}
                    onClose={() => {

                    }}
                >
                    <DialogTitle>
                        It looks like you're new here!
                    </DialogTitle>

                    <DialogContent>
                        <Typography>
                            Would you like to check out the little guide we've prepared for you? It's recommended to read it first before you start your submission!
                        </Typography>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.setState({
                                    guidePrompt: false,
                                    page: 6
                                });
                            }}>Sure, show me the guide</Button>

                        <Button onClick={() => {
                            this.setState({ guidePrompt: false });
                        }}>No, I'm good</Button>
                    </DialogActions>
                </Dialog>


                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={this.state.page} onChange={handleChange}>
                        <Tab disabled={!this.state.actionButton && !this.state.isNewGame} label="Basic Details" />
                        <Tab disabled={!this.state.actionButton || this.state.isNewGame} label="Game Details" />
                        <Tab disabled={!this.state.actionButton || this.state.isNewGame} label="Description" />
                        <Tab disabled={!this.state.actionButton || this.state.isNewGame} label="Releases" />
                        <Tab disabled={!this.state.actionButton || this.state.isNewGame} label="Binary Depot" />
                        <Tab disabled={!this.state.actionButton || this.state.isNewGame} label="Analytics" />
                        <Tab label="Guide" />
                    </Tabs>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        gap: 2,
                    }}
                >
                    <Box
                        sx={{
                            padding: this.state.page < 4 ? 2 : 0,
                            borderRadius: 3, // transparent soft red
                            backgroundColor: this.state.page < 4 ? "#ff000022" : "#00000000",
                            opacity: this.state.page < 4 ? 1 : 0,
                            width: this.state.page < 4 ? 570 : 0,
                            mt: this.state.page < 4 ? 2 : 0,
                            height: this.state.page < 4 ? "fit-content" : 0,
                            transition: "250ms ease-in-out",
                            overflow: "hidden",
                            display: this.state.isNewGame ? "none" : "block"
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#ff3e3e",
                                fontWeight: "bold",
                                fontSize: 20,
                                fontFamily: "Russo One",
                                display: "flex",
                                gap: 1,
                                alignItems: "center"
                            }}
                            gutterBottom
                        >
                            <Warning />WARNING
                        </Typography>

                        <Typography
                            sx={{
                                color: "#ff3e3e",
                                fontSize: 16,
                                fontFamily: "Russo One",
                                textAlign: "left"
                            }}
                        >
                            {this.state.underReview ? "Game is under review. You'll be notified when your game is approved." : "All changes made here will be live the moment you save them. Check twice before saving changes."}
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            padding: this.state.basicDetails.delisted ? 2 : 0,
                            borderRadius: 3, // transparent soft red
                            backgroundColor: this.state.basicDetails.delisted ? "#ff000022" : "#00000000",
                            opacity: this.state.basicDetails.delisted ? 1 : 0,
                            width: this.state.basicDetails.delisted ? 570 : 0,
                            mt: this.state.basicDetails.delisted ? 2 : 0,
                            height: this.state.basicDetails.delisted ? "fit-content" : 0,
                            transition: "250ms ease-in-out",
                            overflow: "hidden",
                            display: !this.state.basicDetails.delisted ? "none" : "block"
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#ff3e3e",
                                fontWeight: "bold",
                                fontSize: 20,
                                fontFamily: "Russo One",
                                display: "flex",
                                gap: 1,
                                alignItems: "center"
                            }}
                            gutterBottom
                        >
                            <Warning />WARNING
                        </Typography>

                        <Typography
                            sx={{
                                color: "#ff3e3e",
                                fontSize: 16,
                                fontFamily: "Russo One",
                                textAlign: "left"
                            }}
                        >
                            Game Delisted, your game is not visible to players. Players who have your game in their library can still download and play it.
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            padding: this.state.page === 3 ? 2 : 0,
                            borderRadius: 3, // transparent soft red
                            backgroundColor: this.state.page === 3 ? "#ff000022" : "#00000000",
                            opacity: this.state.page === 3 ? 1 : 0,
                            width: this.state.page === 3 ? 570 : 0,
                            mt: this.state.page === 3 ? 2 : 0,
                            height: this.state.page === 3 ? "fit-content" : 0,
                            transition: "250ms ease-in-out",
                            overflow: "hidden",
                            display: this.state.isNewGame ? "none" : "block"
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#ff3e3e",
                                fontWeight: "bold",
                                fontSize: 20,
                                fontFamily: "Russo One",
                                display: "flex",
                                gap: 1,
                                alignItems: "center"
                            }}
                            gutterBottom
                        >
                            <Warning />WARNING
                        </Typography>

                        <Typography
                            sx={{
                                color: "#ff3e3e",
                                fontSize: 16,
                                fontFamily: "Russo One",
                                textAlign: "left"
                            }}
                        >
                            Releases are not editable once published! Publish another version instead!
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            padding: this.state.page === 3 ? 2 : 0,
                            borderRadius: 3, // transparent soft red
                            backgroundColor: this.state.page === 3 ? "#0000ff22" : "#00000000",
                            opacity: this.state.page === 3 ? 1 : 0,
                            width: this.state.page === 3 ? 570 : 0,
                            mt: this.state.page === 3 ? 2 : 0,
                            height: this.state.page === 3 ? "fit-content" : 0,
                            transition: "250ms ease-in-out",
                            overflow: "hidden",
                            display: this.state.isNewGame ? "none" : "block"
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#7e7eff",
                                fontWeight: "bold",
                                fontSize: 20,
                                fontFamily: "Russo One",
                                display: "flex",
                                gap: 1,
                                alignItems: "center"
                            }}
                            gutterBottom
                        >
                            <Info />NOTICE
                        </Typography>

                        <Typography
                            sx={{
                                color: "#7e7eff",
                                fontSize: 16,
                                fontFamily: "Russo One",
                                textAlign: "left"
                            }}
                        >
                            Every public release will notify players for an update. Exclusives won't.
                        </Typography>
                    </Box>

                </Box>

                <CustomTabPanel value={this.state.page} index={0}>
                    <Box
                        sx={{
                            m: 2,
                            textAlign: "left"
                        }}
                    >
                        <FormControl
                            fullWidth
                            sx={{
                                mt: 1,
                                justifyContent: "flex-start",
                                width: "45%",
                                gap: 3,
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: 3
                                }}
                            >
                                <Box>
                                    <Typography variant="h6">Game Name</Typography>
                                    <TextField
                                        placeholder="Game Name"
                                        variant="outlined"
                                        sx={{
                                            width: 250,
                                            mt: 1
                                        }}
                                        fullWidth
                                        required
                                        onChange={(ev) => {
                                            this.setState({
                                                basicDetails: {
                                                    ...this.state.basicDetails,
                                                    name: ev.target.value,
                                                }
                                            }, () => {
                                                this.checkValidity()
                                            });
                                        }}
                                        value={this.state.basicDetails.name}
                                        inputProps={{
                                            maxLength: 50
                                        }}

                                    />

                                </Box>

                                <Box>
                                    <Typography variant="h6">Platform</Typography>
                                    <TextField
                                        label="Game Platform"
                                        variant="outlined"
                                        sx={{
                                            width: 250,
                                            textAlign: "left",
                                            mt: 1
                                        }}
                                        fullWidth
                                        required
                                        select
                                        onChange={(ev) => {
                                            this.setState({
                                                basicDetails: {
                                                    ...this.state.basicDetails,
                                                    platform: ev.target.value
                                                }
                                            }, () => {
                                                this.checkValidity()
                                            })


                                        }}
                                        value={this.state.basicDetails.platform}
                                    >
                                        <MenuItem value="win">Windows</MenuItem>
                                        <MenuItem value="web">Web</MenuItem>
                                    </TextField>
                                </Box>
                            </Box>


                            <Typography variant="h6">Support Page</Typography>
                            <Typography sx={{ mt: -2 }} variant="body1">
                                Your monetary support page, if you have one, will be displayed on your game's page as a "Support The Developer" button.
                            </Typography>
                            <TextField
                                placeholder="Support Page"
                                variant="outlined"
                                sx={{
                                    width: 525,
                                    mt: -2
                                }}
                                fullWidth
                                onChange={(ev) => {
                                    this.setState({
                                        basicDetails: {
                                            ...this.state.basicDetails,
                                            supportPage: ev.target.value
                                        }
                                    }, () => {
                                        this.checkValidity()
                                    })
                                }}
                                value={this.state.basicDetails.supportPage}
                                inputProps={{
                                    maxLength: 225
                                }}
                            />

                            <Typography variant="h6">Discord Invite</Typography>
                            <Typography sx={{ mt: -2 }} variant="body1">
                                Your Public Discord Invite Link, if you have one, will be displayed on your game's page as a "Discord Server" button. <strong>Make sure it's a non-expiring non-limited invite link.</strong>
                            </Typography>
                            <TextField
                                placeholder="https://discord.gg/..."
                                variant="outlined"
                                sx={{
                                    width: 525,
                                    mt: -2
                                }}
                                fullWidth
                                onChange={(ev) => {
                                    this.setState({
                                        basicDetails: {
                                            ...this.state.basicDetails,
                                            discordInvite: ev.target.value
                                        }
                                    }, () => {
                                        this.checkValidity()
                                    })
                                }}
                                value={this.state.basicDetails.discordInvite}
                                inputProps={{
                                    maxLength: 225
                                }}
                            />

                            {
                                !this.state.isNewGame &&
                                <Box
                                >
                                    <Typography sx={{
                                    }} variant="h6">Game Visibility</Typography>
                                    <Typography sx={{
                                        mt: 1
                                    }} variant="body1">Delisting your game will make your game disappear from the explore page and will make your game unsearchable. Players who added your game to their libraries will still be able to install and play the game as normal.<br /><strong>If you want to remove your game completely from the platform, please contact us on our Discord server.</strong></Typography>
                                    <FormControlLabel sx={{

                                    }} control={<Checkbox checked={this.state.basicDetails.delisted} onChange={(ev) => {
                                        this.setState({
                                            basicDetails: {
                                                ...this.state.basicDetails,
                                                delisted: ev.target.checked
                                            }
                                        });
                                    }} />} label="Delist Game" />
                                </Box>}
                        </FormControl>

                        <Button
                            disabled={!this.state.actionButton}
                            onClick={() => {
                                if (this.state.isNewGame) {
                                    // create the initial game, save it, reload the page with the new game ID

                                    Util.postRequest("/api/games/saveGameDetails", {
                                        scope: "basicDetails",
                                        gameName: this.state.basicDetails.name,
                                        platform: this.state.basicDetails.platform,
                                        supportPage: this.state.basicDetails.supportPage,
                                        discordInvite: this.state.basicDetails.discordInvite,
                                        delisted: this.state.basicDetails.delisted
                                    }).then((res) => {
                                        // reload the page with the new game ID

                                        // react wont auto-update the state...

                                        // time to do monke

                                        // TODO fix this piece of something code
                                        navigate("/")
                                        setTimeout(() => {
                                            navigate(`/gameManager/${res.appID}`)
                                        }, 500);
                                    })
                                } else {
                                    Util.postRequest("/api/games/saveGameDetails", {
                                        scope: "basicDetails",
                                        gameName: this.state.basicDetails.name,
                                        platform: this.state.basicDetails.platform,
                                        supportPage: this.state.basicDetails.supportPage,
                                        discordInvite: this.state.basicDetails.discordInvite,
                                        delisted: this.state.basicDetails.delisted,
                                        appID: Util.returnCurrentDynamicURL()[2]
                                    }).then((res) => {
                                        // reload the page with the new game ID

                                        // react wont auto-update the state...

                                        // time to do monke

                                        // TODO fix this piece of something code

                                        this.fetchData()

                                        return
                                        let appID = window.location.href.split(window.location.hostname)[1].split("/")[2]
                                        navigate("/")
                                        setTimeout(() => {
                                            navigate(`/gameManager/${appID}`)
                                        }, 500);
                                    })
                                }
                            }}
                            variant="contained"
                            sx={{
                                mt: 2
                            }}
                        >{this.state.isNewGame ? "Create Game" : "Save Changes"}</Button>

                    </Box>
                </CustomTabPanel>

                <CustomTabPanel value={this.state.page} index={1}>
                    <Box
                        sx={{
                            m: 2,
                            textAlign: "left"
                        }}
                    >
                        <FormControl
                            fullWidth
                            sx={{
                                mt: 1,
                                justifyContent: "flex-start",
                                width: "45%",
                                gap: 3,
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Typography variant="h6">Short Description</Typography>
                            <Typography sx={{ mt: -2 }} variant="body1">The short description should be in sentence format and in plaintext. Avoid using white space formatting, bullet points, or other stylistic choices as they will not render properly. Non English characters may not render properly, check with the <a target="_blank" rel="noopener noreferrer" href="https://fonts.google.com/specimen/Montserrat/tester">Montserrat
                                <OpenInNew sx={{
                                    fontSize: 14,
                                    verticalAlign: "middle",
                                    ml: "2px"
                                }} />
                            </a> font for more details.<br /><br />Identify your game's genre and theme (eg. "A fantasy turn-based strategy game")<br />
                                Use specific verbs detailing your game's primary mechanics and player actions<br />
                                Communicate the essential appeal of your game<br />
                                Save details regarding your game's story and setting for your longer description.</Typography>

                            <TextField
                                label="Short Description"
                                variant="outlined"
                                multiline
                                minRows={5}
                                sx={{
                                    width: 620,
                                }}
                                fullWidth
                                required
                                onChange={(ev) => {
                                    this.setState({
                                        gameDetails: {
                                            ...this.state.gameDetails,
                                            shortDescription: ev.target.value
                                        }
                                    }, () => {
                                        this.checkValidity()
                                    })
                                }}
                                value={this.state.gameDetails.shortDescription}
                                inputProps={{
                                    maxLength: 225
                                }}
                                helperText={`${this.state.gameDetails.shortDescription?.length}/225`}
                            />
                        </FormControl>

                        <FormControl
                            fullWidth
                            sx={{
                                mt: 3,
                                justifyContent: "flex-start",
                                width: "45%",
                                gap: 3,
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Box
                                sx={{
                                    padding: this.state.page < 4 ? 2 : 0,
                                    borderRadius: 3, // transparent soft red
                                    backgroundColor: this.state.page < 4 ? "#0000ff22" : "#00000000",
                                    opacity: this.state.page < 4 ? 1 : 0,
                                    width: this.state.page < 4 ? 570 : 0,
                                    mt: this.state.page < 4 ? 2 : 0,
                                    height: this.state.page < 4 ? 117 : 0,
                                    transition: "250ms ease-in-out",
                                    overflow: "hidden",
                                    display: this.state.isNewGame ? "none" : "block"
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: "#7e7eff",
                                        fontWeight: "bold",
                                        fontSize: 20,
                                        fontFamily: "Russo One",
                                        display: "flex",
                                        gap: 1,
                                        alignItems: "center"
                                    }}
                                    gutterBottom
                                >
                                    <Info />NOTICE
                                </Typography>

                                <Typography
                                    sx={{
                                        color: "#7e7eff",
                                        fontSize: 16,
                                        fontFamily: "Russo One",
                                        textAlign: "left"
                                    }}
                                >
                                    Game Icon and Library Banner requires a restart on the client to be visible. It won't propagate immediately.
                                </Typography>
                            </Box>
                            <Typography variant="h6">Game Icon</Typography>
                            <Typography sx={{ mt: -2 }} variant="body1">
                                Your game icon is the image that will be displayed on the library page's game list. It should be a 1:1 aspect ratio image.<br /><strong>Preferably use the same icon as your game's executable as it will be easier for players to identify your game.</strong><br /><strong>If you don't select an icon, your thumbnail will be used instead.</strong>
                            </Typography>

                            <Button
                                variant="contained"
                                sx={{
                                    mt: -2
                                }}

                                onClick={() => {
                                    // open a file dialog to select an image

                                    loadingCaller(true, "Selecting Icon...")

                                    try {
                                        window.ipcRenderer.invoke("selectFile", {
                                            extensions: ["png", "jpg", "jpeg", "webp"],
                                            base64: false,
                                            singleFile: true
                                        }).then((res) => {
                                            if (!res?.filePath) return loadingCaller(false)

                                            window.ipcRenderer.invoke("optimizeWithWebP", {
                                                filePath: res.filePath,
                                            }).then((base64) => {
                                                // create a blob from the base64 string

                                                this.setState({
                                                    gameDetails: {
                                                        ...this.state.gameDetails,
                                                        gameIcon: Util.base64ToBlob(base64)
                                                    }
                                                }, () => {
                                                    loadingCaller(false)
                                                    this.checkValidity()
                                                })
                                            })
                                        })
                                    } catch (e) {
                                        loadingCaller(false)
                                        console.log(e)
                                    }
                                }}
                            >Select Icon</Button>

                            <Typography variant="body1">This is how it'll look like when scaled down to it's UI size.</Typography>
                            {this.state.gameDetails.gameIcon ? <Image
                                src={this.state.gameDetails.gameIcon}
                                style={{
                                    mt: 2,
                                    width: 20,
                                    height: 20
                                }}
                            /> : "No Icon Selected"}

                        </FormControl>

                        <FormControl
                            fullWidth
                            sx={{
                                mt: 3,
                                justifyContent: "flex-start",
                                width: "45%",
                                gap: 3,
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Typography variant="h6">Game Library Banner</Typography>
                            <Typography sx={{ mt: -2 }} variant="body1">
                                The banner to be used on the library's game pane, should be a 96:31 aspect ratio image.
                                <br /><strong>Content on the right side of the image might not be shown on small screens. Game name and playtime will be shown on the bottom left corner.</strong>
                            </Typography>

                            <Button
                                variant="contained"
                                sx={{
                                    mt: -2
                                }}

                                onClick={() => {
                                    // open a file dialog to select an image
                                    loadingCaller(true, "Selecting Banner...")

                                    try {
                                        window.ipcRenderer.invoke("selectFile", {
                                            extensions: ["png", "jpg", "jpeg", "webp"],
                                            base64: false,
                                            singleFile: true
                                        }).then((res) => {
                                            if (!res?.filePath) return loadingCaller(false)

                                            window.ipcRenderer.invoke("optimizeWithWebP", {
                                                filePath: res.filePath,
                                            }).then((base64) => {
                                                // create a blob from the base64 string

                                                this.setState({
                                                    gameDetails: {
                                                        ...this.state.gameDetails,
                                                        gameLibraryBanner: Util.base64ToBlob(base64)
                                                    }
                                                }, () => {
                                                    loadingCaller(false)
                                                    this.checkValidity()
                                                })
                                            })
                                        })

                                    } catch (e) {
                                        loadingCaller(false)
                                        console.log(e)
                                    }
                                }}
                            >Select Banner</Button>

                            <Typography variant="body1">This is how it'll look like when scaled down to it's UI size.</Typography>
                            {this.state.gameDetails.gameLibraryBanner ? <Box
                                style={{
                                    mt: 2,
                                    width: "80vw",
                                    maxHeight: 384,
                                    maxWidth: 1190,
                                    aspectRatio: "96 / 31",
                                    position: "relative",
                                }}
                            >
                                <Image
                                    src={this.state.gameDetails.gameLibraryBanner}
                                    style={{
                                        width: "100%",
                                        aspectRatio: "96 / 31",
                                        objectFit: "cover"
                                    }}
                                />

                                <div
                                    style={{
                                        width: "100%",
                                        position: "absolute",
                                        background: "linear-gradient(0deg, #12121299 0%, rgba(0,0,0,0) 100%)",
                                        top: 0,
                                        aspectRatio: "96 / 31",
                                    }}
                                />

                                <Box
                                    sx={{
                                        position: "absolute",
                                        bottom: 20,
                                        left: 30,
                                        color: "white",
                                        display: "flex",
                                        alignItems: "flex-end",
                                        gap: 4,
                                        zIndex: 2
                                    }}
                                >

                                    <Typography
                                        variant="h4"
                                        style={{

                                        }}
                                    >
                                        {this.state.basicDetails.name}
                                    </Typography>

                                    <Typography
                                        variant="h6"
                                        style={{

                                        }}
                                    >
                                        Playtime: 10h 30m
                                    </Typography>
                                </Box>
                            </Box> : "No Banner Selected"}

                        </FormControl>

                        <FormControl
                            fullWidth
                            sx={{
                                mt: 3,
                                justifyContent: "flex-start",
                                width: "45%",
                                gap: 3,
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Typography variant="h6">Game Page Media</Typography>
                            <Typography sx={{ mt: -2 }} variant="body1">
                                The media to be displayed on your game's page. You can upload images and videos. Drag and drop to reorder the media.<br />
                                <strong>
                                    All media should be in 16:9 aspect ratio, non conforming media will have black bars on the sides.<br />
                                    Images and Videos will be automatically optimized on your device before upload.<br /><br />
                                </strong>
                                <i>Video Limits (Will be forced when optimizing if not met):</i><br />
                                <strong>Video Bitrate:</strong> 10Mbps<br />
                                <strong>Audio Bitrate:</strong> 128kbps<br />
                                <strong>Resolution:</strong> 1080p<br />
                                <strong>FPS:</strong> 60FPS<br />
                                <strong>Video Codec:</strong> H.264 (libx264)<br />
                                <strong>Audio Codec:</strong> AAC<br />
                                <i>Video will be encoded on your device using FFMPEG.<br /><strong>Keep in mind that longer videos will take longer to encode.</strong></i><br /><br />
                                <i>Images will be converted to WebP for bandwidth and storage efficiency.</i>
                            </Typography>

                            <Typography variant="h6">Thumbnail</Typography>
                            <Typography sx={{ mt: -2, mb: -2 }} variant="body1">
                                The thumbnail to be used on your game's page, exploration page and tag highlights. It should be a 73:45 aspect ratio image.<br />This is <strong>REQUIRED</strong> for you to be able to publish your game.<br />Image will be cropped to fit if not in the correct aspect ratio.<br />
                                <strong>Preferably include your game's name in the thumbnail, alongside the main character etc.</strong>
                            </Typography>

                            <MediaPane
                                isThumbnail={true}
                                media={this.state.gameDetails.thumbnail}
                                aspectRatio="73 / 45"
                                width={320}
                                that={this}
                            />

                            <Typography variant="h6">Game Page Images and Videos</Typography>

                            <Typography sx={{ mt: -2 }} variant="body1">
                                Click + to add media, drag and drop to reorder media. Pre-existing media will be replaced with the new media if you select a new one over it.
                                <br />
                                <strong>Empty slots between items will be ignored when saving.</strong>
                            </Typography>

                            <Box
                                sx={{
                                    position: "relative",
                                    width: "90vw",
                                    maxWidth: 1536,
                                    height: 205,
                                    overflow: "hidden",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        height: 205,
                                        width: "90vw",
                                        maxWidth: 1536,
                                        flexDirection: "row",
                                        overflowX: "auto",
                                        gap: 3,
                                        pb: 1,
                                        pl: "20px",
                                        pr: "20px"
                                    }}
                                >
                                    {this.state.gameDetails.gamePageMedia.map((_, i) => {
                                        return <MediaPane
                                            key={`media-${i}`}
                                            id={i}
                                            media={this.state.gameDetails.gamePageMedia[i] || ""}
                                            aspectRatio="16 / 9"
                                            that={this} // man.
                                        />
                                    })}
                                </Box>
                                <div
                                    style={{
                                        // linear gradient to show the end of the list
                                        width: 20,
                                        height: 180,
                                        background: "linear-gradient(90deg, #141414 0%, rgba(0,0,0,0) 100%)",
                                        position: "absolute",
                                        left: 0,
                                        top: 0,
                                        zIndex: 2
                                    }}
                                />

                                <div
                                    style={{
                                        // linear gradient to show the end of the list
                                        width: 20,
                                        height: 180,
                                        background: "linear-gradient(-90deg, #1b1b1b 0%, rgba(0,0,0,0) 100%)",
                                        position: "absolute",
                                        right: 0,
                                        top: 0,
                                        zIndex: 2
                                    }}
                                />
                            </Box>
                        </FormControl>

                        <Button
                            disabled={!this.state.actionButton}
                            onClick={async () => {
                                let shouldPost = false
                                // upload new content first

                                let mediaToUpload = [...this.state.gameDetails.gamePageMedia.filter(m => m != null).map((m, i) => {
                                    return {
                                        media: m,
                                        type: "gamePageMedia",
                                        index: i
                                    }
                                }), {
                                    media: this.state.gameDetails.gameIcon,
                                    type: "gameIcon"
                                }, {
                                    media: this.state.gameDetails.gameLibraryBanner,
                                    type: "gameLibraryBanner"
                                }, {
                                    media: this.state.gameDetails.thumbnail,
                                    type: "thumbnail"
                                }].filter(m => m.media?.startsWith("blob"))

                                if (mediaToUpload.length > 0) {
                                    shouldPost = true
                                }

                                console.log(mediaToUpload)

                                let stateCopy = JSON.parse(JSON.stringify(this.state))
                                console.log(stateCopy.gameDetails.gamePageMedia + " FRT")
                                for (let media of mediaToUpload) {
                                    // Fetch the Blob object from the blob URL
                                    let response = await fetch(media.media)
                                    let blob = await response.blob()

                                    let fname = ""
                                    // set filename
                                    if (typeof media.index === "number") {
                                        fname = this.state.gameDetails.scratchList[media.index].fileName
                                    } else {
                                        fname = `${media.type}-${Date.now()}.webp`
                                    }

                                    // check if the fname is a full on file path, if so, strip it
                                    if (fname.includes("\\") || fname.includes("/")) {
                                        fname = fname.split("\\").pop().split("/").pop()
                                    }

                                    // turn the data as a File object
                                    let tfile = new File([blob], fname, {
                                        type: blob.type,
                                        lastModified: Date.now()
                                    })

                                    let uploadID = (await Util.handleFileUpload(tfile, false, null, true))
                                    console.log(uploadID)

                                    // replace the blob URL with the upload ID
                                    console.log(media)
                                    if (typeof media.index === "number") {
                                        console.log(stateCopy.gameDetails.gamePageMedia)
                                        stateCopy.gameDetails.gamePageMedia[media.index] = uploadID
                                    } else {
                                        console.log(media.type)
                                        stateCopy.gameDetails[media.type] = uploadID
                                    }
                                }

                                // fill the gamePageMedia to the length of 10 again
                                for (let i = stateCopy.gameDetails.gamePageMedia.length; i < 10; i++) {
                                    stateCopy.gameDetails.gamePageMedia.push("")
                                }

                                // media uploads complete

                                // save data

                                Util.postRequest("/api/games/saveGameDetails", {
                                    scope: "gameDetails",
                                    shortDescription: this.state.gameDetails.shortDescription,
                                    gameIcon: stateCopy.gameDetails.gameIcon,
                                    gameLibraryBanner: stateCopy.gameDetails.gameLibraryBanner,
                                    gamePageMedia: stateCopy.gameDetails.gamePageMedia,
                                    thumbnail: stateCopy.gameDetails.thumbnail,
                                    appID: Util.returnCurrentDynamicURL()[2]
                                }).then((res) => {
                                    this.fetchData()
                                })
                            }}
                            variant="contained"
                            sx={{
                                mt: 2
                            }}
                        >{this.state.isNewGame ? "Create Game" : "Save Changes"}</Button>

                    </Box>
                </CustomTabPanel>

                <CustomTabPanel value={this.state.page} index={2}>
                    <Box
                        sx={{
                            m: 2,
                            textAlign: "left"
                        }}
                    >
                        <FormControl
                            fullWidth
                            sx={{
                                mt: 1,
                                justifyContent: "flex-start",
                                width: "45%",
                                gap: 3,
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Typography variant="h5">
                                Game Tags
                            </Typography>

                            <Typography sx={{}} variant="body1">
                                Tags are used to categorize your game and make it easier for players to find your game. You can add as many tags as you'd like. You can add non-existent tags too, but your tags should be single words or short phrases that describe the content briefly.<br />
                                Please write your tags in a consistent format with other tags like, Oral Vore, F/M, Same Size etc.
                            </Typography>

                            <Autocomplete
                                multiple
                                id="name"
                                options={this.state.tagOptions}
                                freeSolo
                                onChange={(e, v) => {
                                    if (!v) return
                                    let val

                                    val = v.map((tag) => {
                                        if (tag.name) {
                                            return tag.name
                                        } else {
                                            return tag
                                        }
                                    })

                                    val = [...new Set(val)]

                                    this.setState({
                                        description: {
                                            ...this.state.description,
                                            tags: val
                                        }
                                    }, () => {
                                        this.checkValidity()
                                    })
                                }}
                                sx={{
                                    width: 450,
                                    mt: -1
                                }}
                                value={this.state.description.tags}
                                getOptionLabel={(option) => option.name || option}
                                filterOptions={(options, params) => {
                                    const { inputValue } = params;
                                    let filtered = [];

                                    if (!inputValue) {
                                        return filtered;
                                    }

                                    // Remove trailing and multiple spaces, then normalize
                                    const normalizedInputValue = inputValue
                                        .trim() //remove trailing spaces
                                        .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
                                        .toLowerCase()
                                        .replace(/ /g, '_');

                                    let isExisting = false;

                                    filtered = options.filter(option => {
                                        const normalizedOptionName = option.name
                                            .trim()
                                            .replace(/\s+/g, ' ')
                                            .toLowerCase()
                                            .replace(/ /g, '_');
                                        const matches = normalizedOptionName.includes(normalizedInputValue);
                                        if (matches) {
                                            isExisting = true
                                        }
                                        return matches
                                    });

                                    // Suggest the creation of a new value if no match is found
                                    if (inputValue.trim() !== '' && !isExisting) {
                                        filtered.push({
                                            inputValue,
                                            name: inputValue,
                                        });
                                    }

                                    return filtered;
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        autoFocus
                                        label="Tags"
                                        type="text"
                                        style={{
                                        }}
                                        fullWidth
                                        onChange={(e) => {
                                            Util.postRequest("/api/tags/autocomplete", { query: e.target.value }).then((res) => {
                                                console.log(res)
                                                this.setState({ tagOptions: res })
                                            })
                                        }}
                                    />
                                )}
                            />

                            <Typography
                                sx={{
                                    mt: 2,
                                    textAlign: "left"
                                }}
                                variant='h5'
                                component="h2"
                            >
                                Game Description
                            </Typography>
                            <Box
                                sx={{
                                    width: "90vw",
                                    maxWidth: 1536,

                                }}
                            >
                                <MarkdownEditor
                                    value={this.state.description.description}
                                    onChange={(ev) => {
                                        this.setState({
                                            description: {
                                                ...this.state.description,
                                                description: ev
                                            }
                                        }, () => {
                                            this.checkValidity()
                                        })
                                    }}
                                />
                            </Box>
                        </FormControl>

                        <Button
                            disabled={!this.state.actionButton}
                            onClick={async () => {

                                // save data

                                Util.postRequest("/api/games/saveGameDetails", {
                                    scope: "description",
                                    description: this.state.description.description,
                                    tags: this.state.description.tags,
                                    appID: Util.returnCurrentDynamicURL()[2]
                                }).then((res) => {
                                    this.fetchData()
                                })
                            }}
                            variant="contained"
                            sx={{
                                mt: 2
                            }}
                        >{this.state.isNewGame ? "Create Game" : "Save Changes"}</Button>
                    </Box>
                </CustomTabPanel>

                <CustomTabPanel value={this.state.page} index={3}>
                    <Box
                        sx={{
                            m: 2,
                            textAlign: "left"
                        }}
                    >
                        <Typography variant="h6">Releases</Typography>
                        <Typography variant="body1">You can see all of your releases here! Not much else I've got to say lol.</Typography>

                        <Button
                            variant='contained'
                            onClick={() => {
                                this.setState({
                                    versionCreationDialogVisible: true
                                })
                            }}
                            sx={{
                                m: 2
                            }}
                        >Create New Release</Button>

                        <Dialog
                            fullScreen
                            open={this.state.versionCreationDialogVisible}
                            onClose={() => {
                                // no action
                            }}
                        >
                            <DialogContent
                                sx={{
                                    mt: "15px"
                                }}
                            >
                                <Typography
                                    variant='h5'
                                >
                                    Create a new release
                                </Typography>
                                <DialogContentText>
                                    Select the binary that you want to assign to this release. This will be the binary that players will download when they download this release. You can only assign a binary that is already in the binary depot. If you haven't already, upload a binary from the Binary Depot tab.
                                </DialogContentText>

                                <FormControl sx={{
                                    mt: 2.5,
                                    gap: 2
                                }} fullWidth>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            width: "100%",
                                            gap: 3
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <TextWithTooltip title="This is the version name that will be seen by players">Version Name</TextWithTooltip>

                                            <TextField
                                                placeholder="Version Name"
                                                variant="outlined"
                                                sx={{
                                                    width: 220
                                                }}
                                                onChange={(ev) => {
                                                    this.setState({
                                                        release: {
                                                            ...this.state.release,
                                                            versionName: ev.target.value
                                                        }
                                                    })
                                                }}
                                                fullWidth
                                                value={this.state.release.versionName}
                                            />

                                        </Box>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <TextWithTooltip title="
                                                This is the binary that will be assigned to this release. This binary will be the one that players will download when they download this release. You can only assign a binary that is already in the binary depot. If you haven't already, upload a binary from the Binary Depot tab.
                                            ">Assigned Binary</TextWithTooltip>

                                            <Select
                                                value={this.state.release.binary}
                                                onChange={(ev) => {
                                                    console.log(ev.target.value)
                                                    this.setState({
                                                        release: {
                                                            ...this.state.release,
                                                            binary: ev.target.value
                                                        }
                                                    })
                                                }}
                                                sx={{
                                                    width: 220 // this is good enough for even 3 digit versionIDs
                                                }}
                                            >
                                                <MenuItem value="-1" disabled>Select a binary</MenuItem>
                                                {this.state.binaryDepot?.map((r, i) => {
                                                    return (
                                                        <MenuItem key={`binaryDepotSelect-${i}`} value={r._id}>v{r.binaryID} - {new Date(r.created).toDateString()}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </Box>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            width: "100%",
                                            gap: 3
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <TextWithTooltip title="
                                                After this date your release will be visible to players. If you want to release the game at a later date, you can set this date to a future date. If not, set it to a past date or don't set anything
                                            ">Release After</TextWithTooltip>

                                            <TextField
                                                type="date"
                                                variant="outlined"
                                                sx={{
                                                    width: 220
                                                }}
                                                fullWidth
                                                value={this.state.release.releaseAfter}
                                                onChange={(ev) => {
                                                    this.setState({
                                                        release: {
                                                            ...this.state.release,
                                                            releaseAfter: ev.target.value
                                                        }
                                                    })
                                                }}
                                            />
                                        </Box>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: 1
                                            }}
                                        >
                                            <TextWithTooltip title="Exclusive releases are not public and will require a key to be installed, be sure to share the key with players you want to have access!">Release Exclusivity</TextWithTooltip>

                                            <FormControlLabel control={<Checkbox
                                                checked={this.state.release.isExclusive}
                                                onChange={(ev) => {
                                                    this.setState({
                                                        release: {
                                                            ...this.state.release,
                                                            isExclusive: ev.target.checked
                                                        }
                                                    })
                                                }}
                                            />} label="Exclusive Release" />

                                        </Box>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: 1,
                                                ml: 2
                                            }}
                                        >
                                            <TextWithTooltip title="This is the key that'll be required for players to be able to install this version. Make sure to share it with the playerbase you want to give access!">Exclusive Access Key</TextWithTooltip>

                                            <TextField
                                                disabled={this.state.release.isExclusive === false}
                                                placeholder="Exclusive Access Key"
                                                variant="outlined"
                                                sx={{
                                                    width: 380
                                                }}
                                                fullWidth
                                                value={this.state.release.key}
                                                onChange={(ev) => {
                                                    this.setState({
                                                        release: {
                                                            ...this.state.release,
                                                            key: ev.target.value
                                                        }
                                                    })
                                                }}
                                                // random key button
                                                InputProps={{
                                                    endAdornment: <Box
                                                        sx={{
                                                            display: "flex"
                                                        }}
                                                    ><InputAdornment position="end">
                                                            <Tooltip title="Generate a random key">
                                                                <IconButton
                                                                    disabled={this.state.release.isExclusive === false}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            release: {
                                                                                ...this.state.release,
                                                                                key: Util.generateRandomAccessKey(16)
                                                                            }
                                                                        })
                                                                    }}
                                                                >
                                                                    <Casino />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </InputAdornment>

                                                        <InputAdornment position="end">
                                                            <Tooltip title="Copy">
                                                                <IconButton
                                                                    disabled={this.state.release.isExclusive === false}
                                                                    onClick={() => {
                                                                        navigator.clipboard.writeText(this.state.release.key)

                                                                        snackCaller("Copied to clipboard!")
                                                                    }}
                                                                >
                                                                    <ContentCopy />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </InputAdornment></Box>,
                                                    inputProps: {
                                                        maxLength: 24
                                                    }
                                                }}
                                            />
                                        </Box>
                                    </Box>

                                    <Box>
                                        <Typography
                                            variant='h5'
                                        >
                                            Changelog
                                        </Typography>

                                        <Typography
                                            variant='body1'
                                            gutterBottom
                                        >
                                            Write a changelog for this release. This will be displayed to players in the game's library page.
                                        </Typography>

                                        {this.state.release.changelog === null ?
                                            <MarkdownEditor
                                                value={changelogGuide}
                                                onChange={(ev) => {
                                                    this.setState({
                                                        release: {
                                                            ...this.state.release,
                                                            changelog: ""
                                                        }
                                                    })
                                                }}
                                            />
                                            :
                                            <MarkdownEditor
                                                value={this.state.release.changelog}
                                                onChange={(ev) => {
                                                    this.setState({
                                                        release: {
                                                            ...this.state.release,
                                                            changelog: ev
                                                        }
                                                    })
                                                }}
                                            />
                                        }
                                    </Box>
                                </FormControl>
                            </DialogContent>

                            <DialogActions>
                                <Button
                                    onClick={() => {
                                        this.setState({
                                            versionCreationDialogVisible: false
                                        })
                                    }}
                                    variant="outlined"
                                >Cancel</Button>

                                <Button
                                    disabled={
                                        this.state.release.binary === "-1" ||
                                        this.state.release.versionName === ""
                                    }
                                    onClick={() => {
                                        Util.postRequest("/api/games/saveGameDetails", {
                                            scope: "releases",
                                            appID: Util.returnCurrentDynamicURL()[2],
                                            releaseName: this.state.release.versionName,
                                            binary: this.state.release.binary,
                                            releasesAfter: this.state.release.releaseAfter,
                                            exclusivityState: this.state.release.isExclusive,
                                            key: this.state.release.key,
                                            changelog: this.state.release.changelog
                                        }).then((res) => {
                                            if (res?.message && res.message === "Release created successfully") {
                                                this.setState({
                                                    versionCreationDialogVisible: false
                                                }, () => {
                                                    this.fetchData()

                                                    return
                                                    // reload the page
                                                    let appID = window.location.href.split(window.location.hostname)[1].split("/")[2]
                                                    navigate("/")
                                                    setTimeout(() => {
                                                        navigate(`/gameManager/${appID}`)
                                                    }, 500);
                                                })
                                            }
                                        })
                                    }}
                                    variant="contained"
                                >Publish Release</Button>
                            </DialogActions>
                        </Dialog>

                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Version ID</TableCell>
                                        <TableCell>Created</TableCell>
                                        <TableCell>Binary ID</TableCell>
                                        <TableCell>Is Exclusive?</TableCell>
                                        <TableCell>Key</TableCell>
                                        <TableCell>Releases After</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.state.releases?.map((row, i) => (
                                            <TableRow
                                                key={`release-${i}`}
                                            >
                                                <TableCell component="th" scope="row">
                                                    v{row.versionID}
                                                    {i === 0 && <Tooltip
                                                        title="This release is the latest public release of the game on Vorecade"
                                                    ><Chip
                                                            label="Latest"
                                                            color="primary"
                                                            sx={{
                                                                ml: 1
                                                            }}
                                                        /></Tooltip>}

                                                    {row.key && <Tooltip
                                                        title="This release is set as an exclusive and won't appear as an update on the public version"
                                                    ><Chip
                                                            label="Exclusive"
                                                            color="success"
                                                            sx={{
                                                                ml: 1
                                                            }}
                                                        /></Tooltip>}

                                                    {i === this.state.releases.length - 1 && <Tooltip
                                                        title="This release is the initial release of the game on Vorecade"
                                                    ><Chip
                                                            label="Initial Release"
                                                            color="success"
                                                            sx={{
                                                                ml: 1
                                                            }}
                                                        /></Tooltip>}

                                                    {(i !== this.state.releases.length - 1 && this.state.releases[i + 1]?.binary !== row.binary) && <Tooltip
                                                        title="This release is a hard update, meaning that the binary is different from the previous release"
                                                    ><Chip
                                                            label="Hard Update"
                                                            color="error"
                                                            sx={{
                                                                ml: 1
                                                            }}
                                                        /></Tooltip>}

                                                    {(i !== this.state.releases.length - 1 &&
                                                        this.state.binaryDepot.find((b) => {
                                                            return b._id === this.state.releases[i + 1].binary
                                                        })?.binaryID
                                                        >
                                                        this.state.binaryDepot.find((b) => {
                                                            return b._id === row.binary
                                                        })?.binaryID
                                                    ) && <Tooltip
                                                        title="This release is considered a rollback because it reverts the game to a previous version"
                                                    ><Chip
                                                                label="Rollback"
                                                                color="info"
                                                                sx={{
                                                                    ml: 1
                                                                }}
                                                            /></Tooltip>}

                                                    {(i !== this.state.releases.length - 1 && this.state.releases[i + 1]?.binary === row.binary) && <Tooltip
                                                        title="This release is a soft update, meaning that the binary is the same as the previous release"
                                                    ><Chip
                                                            label="Soft Update"
                                                            color="warning"
                                                            sx={{
                                                                ml: 1
                                                            }}
                                                        /></Tooltip>}
                                                </TableCell>
                                                <TableCell>{new Date(row.created).toLocaleDateString()}</TableCell>
                                                <TableCell>{this.state.binaryDepot.find((b) => {
                                                    return b._id === row.binary
                                                })?.binaryID}</TableCell>
                                                <TableCell>{row.key ? "Yes" : "No"}</TableCell>
                                                <TableCell>{row.key}</TableCell>
                                                <TableCell>{row.releasesAfter ? new Date(row.releasesAfter).toLocaleDateString() : "Yes"}</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </CustomTabPanel>

                <CustomTabPanel value={this.state.page} index={4}>
                    <Box
                        sx={{
                            m: 2,
                            textAlign: "left"
                        }}
                    >
                        <Dialog
                            open={this.state.binarySelectionDialogVisible}
                            onClose={() => {
                                this.setState({
                                    binarySelectionDialogVisible: false
                                })
                            }}
                        >
                            <DialogContent>
                                <Typography
                                    variant='h5'
                                    gutterBottom
                                >Select your game's installation directory</Typography>
                                <DialogContentText>Make sure to remove everything that's not necessary for the game's execution from the installation directory.</DialogContentText>

                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                        mb: 1
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: 2,
                                            mb: 1
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: 2,
                                                mb: 1
                                            }}
                                        >
                                            <Typography sx={{ mb: -2, mt: 2 }}>Executable Filename</Typography>
                                            <TextField
                                                placeholder="Game.exe Game.html etc."
                                                variant="outlined"
                                                sx={{
                                                    width: 250,
                                                }}
                                                fullWidth
                                                required
                                                onChange={(ev) => {
                                                    this.setState({
                                                        executablePath: ev.target.value,
                                                    }, () => {
                                                        this.setState({
                                                            nextButton: this.checkValidity()
                                                        })
                                                    })
                                                }}
                                                value={this.state.executablePath}
                                            />
                                            <Typography
                                                sx={{
                                                    textAlign: "left",
                                                    fontSize: 12,
                                                    mt: -1.5,
                                                }}
                                            >
                                                Please correct if it is incorrect
                                            </Typography>

                                            <Button
                                                variant="contained"

                                                sx={{
                                                    width: 250,
                                                }}

                                                onClick={async () => {
                                                    let folder = await window.ipcRenderer.invoke("selectFolder")
                                                    if (!folder) return
                                                    let folderData = await window.ipcRenderer.invoke("handleGameFolder", { folder: folder })
                                                    loadingCaller(true, "Calculating Entropy...")
                                                    let entropy = await window.ipcRenderer.invoke("calculateFolderEntropy", { folder: folder })
                                                    loadingCaller(false)

                                                    this.setState({
                                                        executablePath: folderData.autoDetectedEXERelativePath,
                                                        preCompressSize: (folderData.totalSize / 1024 / 1024).toFixed(0),
                                                        estimatedCompressSize: ((folderData.totalSize / 1024 / 1024) * entropy / 100).toFixed(0),
                                                        entropy: entropy,
                                                        binary: folder
                                                    })
                                                }}
                                            >
                                                Select Game Directory
                                            </Button>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: 2,
                                                mb: 1
                                            }}
                                        >
                                            <Typography sx={{ mb: -2, mt: 2 }}>Comment</Typography>
                                            <TextField
                                                placeholder="Version 1.0.0 etc."
                                                variant="outlined"
                                                sx={{
                                                    width: 250,
                                                }}
                                                fullWidth
                                                required
                                                onChange={(ev) => {
                                                    this.setState({
                                                        executableComment: ev.target.value,
                                                    }, () => {
                                                        this.setState({
                                                            nextButton: this.checkValidity()
                                                        })
                                                    })
                                                }}
                                                value={this.state.executableComment}
                                            />
                                        </Box>
                                    </Box>
                                </Box>

                                <Typography
                                    sx={{
                                        textAlign: "left"
                                    }}
                                >
                                    Game Size (Pre-compress): {this.state.preCompressSize} MB<br />
                                    Game Size (Estimated Compression): {this.state.estimatedCompressSize} MB
                                </Typography>
                            </DialogContent>

                            <DialogActions>
                                <Button
                                    onClick={() => {
                                        this.setState({
                                            binarySelectionDialogVisible: false,
                                            preCompressSize: 0,
                                            estimatedCompressSize: 0,
                                            executablePath: "",
                                            comment: ""
                                        })
                                    }}
                                    variant="outlined"
                                >Cancel</Button>

                                <Button
                                    disabled={!this.state.binary}
                                    onClick={async () => {
                                        try {
                                            loadingCaller(true, "Packing and Uploading Binary...")
                                            let fID = await window.ipcRenderer.invoke("packAndUploadBinary", {
                                                folder: this.state.binary,
                                                appID: Util.returnCurrentDynamicURL()[2]
                                            })

                                            // save the binary to the database

                                            loadingCaller(true, "Telling the server...")
                                            Util.postRequest("/api/games/saveGameDetails", {
                                                scope: "binaryUpload",
                                                fileID: fID,
                                                comment: this.state.comment,
                                                appID: Util.returnCurrentDynamicURL()[2],
                                                executablePath: this.state.executablePath,
                                            }).then((res) => {
                                                console.log(res)
                                                loadingCaller(false)
                                                this.setState({
                                                    binarySelectionDialogVisible: false
                                                })
                                                this.fetchData()
                                            }).catch((err) => {
                                                loadingCaller(false)
                                                this.setState({
                                                    binarySelectionDialogVisible: false
                                                })
                                                snackCaller("An error occurred while uploading the binary. Please try again later.")
                                                console.log(err)
                                            })
                                        } catch (e) {
                                            console.log(e)
                                            loadingCaller(false)
                                        }
                                    }}
                                    variant="contained"
                                >Upload</Button>
                            </DialogActions>
                        </Dialog>

                        <Typography variant="h6">Binary Depot</Typography>
                        <Typography variant="body1">The binary depot is where you can upload your game's files. Upload your files here first, then create a new release from the Releases tab.<br />
                            <strong>Any binary that has not been assigned to a release will stay private.</strong>
                        </Typography>

                        <Button
                            variant='contained'
                            sx={{
                                m: 2
                            }}
                            onClick={() => {
                                this.setState({
                                    binarySelectionDialogVisible: true
                                })
                            }}
                        >Upload New Version</Button>

                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Binary ID</TableCell>
                                        <TableCell>Created</TableCell>
                                        <TableCell>Comment</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.state.binaryDepot.map((row, i) => (
                                            <TableRow
                                                key={`binary-${i}`}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {this.state.binaryDepot.length - i}
                                                </TableCell>
                                                <TableCell>{new Date(row.created).toLocaleDateString()}</TableCell>
                                                <TableCell>{row.comment}</TableCell>
                                                <TableCell>{Math.trunc(row?.binary?.size / 1024 / 1024)}MB</TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="contained"
                                                        onClick={() => {
                                                            // download the binary
                                                            console.log(wrapperForMySanity(row.binary.UUID))
                                                            window.ipcRenderer.invoke("downloadFile", {
                                                                url: wrapperForMySanity(row.binary.UUID),
                                                                fileName: row.binary.filename,
                                                                md5: ""
                                                            })
                                                        }}
                                                    >Download</Button>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </CustomTabPanel>

                <CustomTabPanel value={this.state.page} index={5}>
                    <AnalyticsRoot />
                </CustomTabPanel>

                <CustomTabPanel value={this.state.page} index={6}>
                    <Box
                        sx={{
                            textAlign: "left"
                        }}
                    >
                        <Markdown
                            markdown={`
# Usage Guide

**This is the Game Manager, where you can manage your game's details, releases, binaries and analytics. Here's a quick guide on how to use the Game Manager.**

*It is recommended to follow the steps in the given order, but you can jump around as you like.*

You can switch to the relevant tab by the tab-bar above.

## ***IMPORTANT NOTE***
### You need to save each Tab <ins>_**SEPERATELY.**_</ins> Saving one tab does not save the others and vice-versa. Saving one tab will discard all changes in other tabs.

### Basic Details (*Start Here!*)

First off, you need to fill in the basic details of your game. This includes the game's name and platform. You can also fill out your Monetary Support link and Discord invite here.

![Basic Details UI](https://vorecade.com/api/content/file/c935749a-7a6d-475a-91c8-ba7964634501)

### Game Details

Next, you can fill in the game's details. This includes the game's short description, Library Icon, Library Banner, Thumbnail (**Required**), and Game Page Media.

***Make sure to pay attention to the aspect ratios and video limitations!***

You can also re-order items by drag-dropping them on eachother.
![Game Page Media UI](https://vorecade.com/api/content/file/78657fe5-df54-4c99-b854-bd86be46fba7)

### Description (and Tags)

You can fill in the game's description and tags here. Tags are used to categorize your game and make it easier for players to find your game. You can add as many tags as you'd like. You can add non-existent tags too, but your tags should be single words or short phrases that describe the content briefly. Don't go overboard as unnecessary and irrelevant tags will be removed.

You can also toggle the Markdown preview if you'd like to by clicking the Preview button.

![Preview Toggler](https://vorecade.com/api/content/file/78254fca-2177-4573-bb4a-f44d6de0a253)

### Binaries

Binaries tab is where you upload your game files. You have to upload your game files here first, then you can assign them to a release afterwards in the Releases tab. Any binary that has not been assigned to a release will stay private.

<ins>***Do note that binaries cannot be deleted after uploading.***</ins>

***Make sure to remove everything that's not necessary for the game's execution from the installation directory.***

***Make sure to select the correct executable file. Correct if auto-detection does not. Selecting the wrong executable will render your game <ins>UNPLAYABLE.</ins>***

![Binary Selection](https://vorecade.com/api/content/file/2b312217-0ec1-4eb8-9de9-3fbc03253f24)

(e.g Don't select the UnityErrorHandler as the auto-detection sometimes picks that up instead of the game.)


### Releases

This is where the real magic happens! ***And the point you need to complete for your game to be reviewed.***

You can create a new release by clicking the "Create New Release" button. You can assign a binary to the release, set a release date, and set an access key if you want to make the release exclusive. You can also write a changelog for the release (You definitely should!).

***You can also learn more about individual features by the "i" icon next to the feature.***

# You're all set! Good luck with your game! 🎉

*If you have any more questions or need help, feel free to ask in the Vorecade Discord server here: https://discord.gg/rem3TJuRes*

*Wouldn't mind if you had any recommendations or feedback either!*
                            `}
                        />
                    </Box>
                </CustomTabPanel>
            </Box>
        );
    }
};