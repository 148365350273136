import Button from '@mui/material/Button';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Util from '../util';
import { IconButton, Tooltip } from '@mui/material';
import { Edit } from '@mui/icons-material';
import LinearGradient from '../components/LinearGradient';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { snackCaller, confetti } from '../App';
import Markdown from '../components/Markdown';
import { GameCard } from '.';
import { GamePane } from './GameEditor';
import Image from '../components/Image';
import { FireflyCanvas } from '../components/Fireflies';
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Profile = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user") || "false") || {});
    const [games, setGames] = useState([]);
    const [value, setValue] = useState(0);
    const [changeTarget, setChangeTarget] = useState("")
    const [isCakeDay, setIsCakeDay] = useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        let urlID = Util.returnCurrentDynamicURL().pop();

        Util.postRequest(`/api/user/profile/${urlID}`, { id: urlID }).then(async (res) => {
            if (res.puser?.UUID == null) {
                return navigate("/");
            }
            if (res.puser.UUID === user.UUID) {
                localStorage.setItem("user", JSON.stringify(res.puser));
            }
            setUser(res.puser);

            setGames(res.games);

            if (res.isCakeDay) {
                setIsCakeDay(true);
                for (let i = 0; i < 3; i++) {
                    if (Util.returnCurrentDynamicURL().pop() !== urlID) return;
                    await new Promise(r => {
                        confetti.addConfetti({
                            confettiColors: ["#c85aef", "#9b4de3", "#8a2be2", "#6a0dad", "#7a4ff1", "#8e44ad", "#b57edc", "#d4a7d6", "#ba55d3", "#9932cc", "#6a0cb8"]
                        })
                        setTimeout(() => {
                            confetti.addConfetti({
                                emojis: ["🐎", "🐴", "🎂", "🍰", "🎉", "🎈", "🎁", "🥳", "🎊", "🧁", "🍾", "🎇", "🎆", "🎀", "🎁", "🎈", "🎉", "🎊", "🎂", "🍰", "🧁", "🍾", "🥂", "🎁", "🎈", "🎉", "🎊", "🎂", "🍰", "🧁", "🍾", "🥂", "🎁", "🎈", "🎉", "🎊", "🎂", "🍰", "🧁", "🍾", "🥂", "🎁", "🎈", "🎉", "🎊", "🎂", "🍰", "🧁", "🍾", "🥂", "🎁", "🎈", "🎉", "🎊", "🎂", "🍰", "🧁", "🍾", "🥂", "🎁", "🎈", "🎉", "🎊", "🎂", "🍰", "🧁", "🍾", "🥂", "🎁", "🎈", "🎉", "🎊", "🎂", "🍰", "🧁", "🍾", "🥂", "🎁", "🎈", "🎉", "🎊", "🎂", "🍰", "🧁", "🍾", "🥂", "🎁", "🎈", "🎉", "🎊"],
                                emojiSize: 30
                            })
                            setTimeout(() => {
                                confetti.addConfetti({
                                    confettiColors: ["#c85aef", "#ff77ff", "#ff69b4", "#ff00ff", "#ffb6c1", "#f5a9d8", "#d1b3ff", "#f0e68c", "#ffcc00", "#ffa500", "#00f5ff"]
                                })

                                setTimeout(() => {
                                    confetti.addConfetti({
                                        confettiColors: ["#FF5733", "#1F6DFF", "#FFEB33", "#39FF14", "#FF33F6", "#FF8C00", "#FF1493", "#30D5C8", "#32CD32", "#800080"],
                                    })

                                    r();
                                }, 1000);
                            }, 1500);
                        }, 2000);
                    });
                }
            }
        });

        return () => {
            confetti.clearCanvas();
        }
    }, [window.location.href]);

    const fileInputRef = useRef(null);

    const handleBannerUploadClick = () => {
        setChangeTarget("changeBanner")
        fileInputRef.current.click();
    };

    const handleAvatarUploadClick = () => {
        setChangeTarget("changeAvatar")
        fileInputRef.current.click();
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (!file) return;
        const reader = new FileReader();

        reader.onloadend = () => {
            if (!changeTarget) return snackCaller("No change target set");
            // send to server as a form
            let file = event.target.files[0];
            // optimize the image
            Util.convertImageToWebp(file).then((res) => {
                file = res;
                Util.handleFileUpload(file, false, null, true).then((res) => {
                    Util.postRequest("/api/user/" + changeTarget, { file: res }).then((res) => {
                        setUser({ ...user, avatar: res.avatar });
                    })
                })
            })
        };

        reader.readAsDataURL(file);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ width: "100%", }}>
                <Box
                    sx={{
                        width: "100%",
                        alignSelf: 'center',
                        position: 'relative',
                        aspectRatio: "186 / 25",
                    }}
                >
                    <Image
                        src={user.banner}
                        alt="banner"
                        style={{
                            height: "100%",
                            minWidth: 950,
                            maxWidth: "100%",
                            objectFit: "cover",
                            position: 'absolute',
                            zIndex: -1,
                            left: 0,
                            aspectRatio: "186 / 25",
                            overflow: "hidden",
                        }}
                    />
                    <Box
                        sx={{
                            height: 200,
                            width: "100%",
                            position: 'absolute',
                            zIndex: 0,
                            backdropFilter: 'blur(2px)',
                        }}
                    >
                        <FireflyCanvas
                            colors={[
                                "#c85aef", "#9b4de3", "#8a2be2", "#6a0dad", "#7a4ff1", "#8e44ad", "#b57edc", "#d4a7d6", "#ba55d3", "#9932cc", "#6a0cb8"
                            ]}
                        />
                    </Box>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        width: "100%",
                        height: 200,
                        position: 'relative',
                    }}>
                        <Button
                            sx={{
                                position: 'absolute',
                                top: 10,
                                right: 10,
                                backgroundColor: "#00000080",
                                color: "white",
                                '&:hover': {
                                    backgroundColor: "#00000080",
                                },
                                backdropFilter: 'blur(5px)',
                                display: user.username === JSON.parse(localStorage.getItem("user") || "{}").username ? "flex" : "none"
                            }}
                            onClick={handleBannerUploadClick}
                        >
                            <Edit />
                        </Button>
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            accept="image/*"
                            onChange={handleFileChange}
                        />
                        <div
                            style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                pointerEvents: "none"
                            }}
                        >
                            <LinearGradient x1={1} y1={0} x2={0} y2={0} color1="#00000000" color2="#00000080" />
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 2,
                                position: 'absolute',
                                top: 80,
                                left: 10,
                                width: "calc(100% - 10px)",
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: "flex-end",
                                    marginLeft: 20,
                                    position: "relative",
                                    width: 100,
                                    height: 100,
                                }}
                            >
                                <Image src={user.avatar || "https://cdn.vorecade.com/defaults/avatar.webp"} alt="avatar" style={{ objectFit: "cover", zIndex: 1, width: 100, height: 100, borderRadius: "50%", }} />
                                <div
                                    style={{
                                        position: "absolute",
                                        bottom: 0,
                                        right: 0,
                                        backgroundColor: "white",
                                        borderRadius: "50%",
                                        width: 100,
                                        height: 100,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        boxShadow: `0px 0px 5px 3px ${user.isOnline ? "#00ff00" : "red"}`,
                                        zIndex: 0
                                    }}
                                />
                                <div
                                    style={{
                                        position: "absolute",
                                        bottom: 0,
                                        right: 0,
                                        backgroundColor: "white",
                                        borderRadius: "50%",
                                        width: 30,
                                        height: 30,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        boxShadow: "0px 0px 5px 0px black",
                                        zIndex: 2,
                                        display: user.username === JSON.parse(localStorage.getItem("user") || "{}").username ? "flex" : "none"
                                    }}
                                >
                                    <IconButton
                                        onClick={handleAvatarUploadClick}
                                        color="primary"
                                    >
                                        <Edit />
                                    </IconButton>
                                </div>
                            </div>


                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: "flex-end",
                                }}
                            >
                                <div
                                    style={{
                                        justifyContent: "flex-end",
                                        display: "flex",
                                        marginLeft: 20,
                                        flexDirection: "row",
                                        paddingBottom: 20
                                    }}
                                >
                                    <Typography variant="h4" component="h1">
                                        {user.username} {isCakeDay && <Tooltip
                                            title={`It's ${user.username}'s personal cake day! 🍰`}
                                        >🍰</Tooltip>}
                                    </Typography>
                                </div>

                                <div
                                    style={{
                                        justifyContent: "flex-end",
                                        display: "flex",
                                        marginLeft: 20,
                                        flexDirection: "row",
                                        position: "absolute"
                                    }}
                                >
                                    <Typography component="p">
                                        {Util.getUserRank(user.rank)}
                                    </Typography>
                                </div>
                            </div>


                            <Box
                                sx={{
                                    display: { xs: 'none', sm: 'block' },
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: "flex-end",
                                        marginRight: 20,
                                        right: 20,
                                        position: "absolute",
                                        bottom: 0
                                    }}
                                >
                                    <div
                                        style={{
                                            justifyContent: "flex-end",
                                            display: "flex",
                                            marginLeft: 20,
                                            flexDirection: "column",
                                            textAlign: "right"
                                        }}
                                    >
                                        <Typography component="p">
                                            Member since {new Date(user.created).toLocaleDateString()}
                                        </Typography>

                                        <Typography component="p">
                                            Last Seen {new Date(user.lastSeen).toLocaleTimeString() + " " + new Date(user.lastSeen).toLocaleDateString()}
                                        </Typography>
                                    </div>
                                </div>
                            </Box>
                        </div>
                    </div>
                </Box>
            </div>

            <Box sx={{ width: '100%' }}>
                <Box
                    sx={{
                        margin: 2,
                        textAlign: "left"
                    }}
                >
                    <Typography
                        variant="h6" component="h2"
                        sx={{

                        }}
                    >
                        About {user.username}
                    </Typography>

                    <Markdown
                        markdown={user.about || "This user has not set an \"About Me\" yet. Have you?"}
                    />
                </Box>

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label={`${user.username}'s Games`} {...a11yProps(0)} />
                        <Tab disabled label="Galleries" {...a11yProps(1)} />
                        <Tab disabled label="Threads" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    {games?.length === 0 ?
                        <Box>
                            <Typography>
                                This user hasn't created any games yet.
                                <br /><br />
                                <i style={{
                                    fontSize: "0.7em",
                                }}>game library showoff when??</i>
                            </Typography>

                            {
                                user.UUID === JSON.parse(localStorage.getItem("user") || "{}").UUID &&
                                <GamePane
                                    isCreate={true}
                                    navigate={navigate}
                                />}
                        </Box>
                        :
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                gap: 2,
                                justifyContent: 'flex-start',
                            }}
                        >
                            {
                                games?.map((game) => (
                                    <GameCard key={game.id} game={game} navigate={navigate} />
                                ))
                            }
                            {
                                user.UUID === JSON.parse(localStorage.getItem("user") || "{}").UUID &&
                                <GamePane
                                    isCreate={true}
                                    navigate={navigate}
                                />}
                        </Box>
                    }
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    Item Two
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    Item Three
                </CustomTabPanel>

                <div
                    style={{
                        width: 1,
                        height: 75,
                    }}
                />
            </Box>
        </Box>
    );
};

export default Profile;