import * as App from './App';
import { useNavigate } from 'react-router-dom';

export function isElectron() {
    let isEl = (() => {
        // Renderer process"
        if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
            return true;
        }

        // Main process
        if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
            return true;
        }
    })();

    return isEl;
}

export function isDevMode() {
    const isDev = window.NODE_ENV === 'development';
    return !isDev;
}

export const wrapperForMySanity = (UUID) => isDevMode() ? `http://localhost:5000/api/content/file/${UUID}` : `https://vorecade.com/api/content/file/${UUID}`

export let functionRoot = ""

// Usage
if (isElectron()) {
    console.log('Running inside Electron');
    if (isDevMode() || process.env.NODE_ENV === 'development') {
        functionRoot = "http://localhost:5000" //"https://vorecade.com" // USE LATEST, USE LOCALHOST FOR TESTING
    } else {
        functionRoot = "https://vorecade.com"
    }
} else {
    functionRoot = process.env.REACT_APP_API_BASE_URL
}

function base64urlToArrayBuffer(base64url) {
    const base64 = base64url.replace(/-/g, '+').replace(/_/g, '/');
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

let isConnected = false
let retries = 0

async function awaitConnection() {
    setTimeout(() => {
        if (!isConnected) {
            App.loadingCaller(true)
        }
    }, 3000);
    while (!isConnected) {
        if (retries === 3) {
            alert("Vorecade couldn't connect to the server.")
            break
        }
        await new Promise((res) => setTimeout(() => {
            fetch(`${functionRoot}/204`).then((res) => {
                if (res.status === 204) {
                    isConnected = true
                    App.loadingCaller(false)
                    return res()
                }
                retries++
                res()
            }).catch(e => {
                console.log(`cant gen 204\n\n${e}`)
                retries++
                res()
            })
        }, 1000))
    }
}

export function postRequest(url, data, progressCallback, contentType = 'application/json', nR = false) {
    return new Promise((resolve, reject) => {
        let loadcaller = setTimeout(() => {
            App.callLoadingLine(0, "Waiting for server...")
        }, 1000);
        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${functionRoot}${url}`, true);
        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem("token") || '');

        if (!(data instanceof FormData)) {
            xhr.setRequestHeader('Content-Type', contentType);
            data = JSON.stringify(data);
        }

        xhr.upload.onprogress = function (event) {
            if (event.lengthComputable) {
                const percentCompleted = Math.round((event.loaded * 100) / event.total);
                progressCallback && progressCallback(percentCompleted);
            }
        };

        xhr.onload = function () {
            App.callLoadingLine(-1, "")
            clearTimeout(loadcaller);
            let response;

            try {
                response = JSON.parse(this.response);
            } catch (e) {
                response = {};
            }

            if (this.status === 200) {
                response.token && localStorage.setItem("token", response.token);
                response.user && localStorage.setItem("user", JSON.stringify(response.user));

                if (response.redirect && !nR) {
                    App.navigate(response.redirect);
                }

                if (response.message && url !== "/api/scii" && url !== "/api/scen") {
                    App.snackCaller(response.message);
                }

                resolve(response);
            } else {
                if (response.redirect && !nR) {
                    App.navigate(response.redirect);
                }

                if (response.message && !nR) {
                    App.snackCaller(response.message);
                }

                reject({ message: this.statusText });
            }
        };

        xhr.onerror = function () {
            reject({ message: this.statusText });
        };

        xhr.send(data);
    });
}

export default {
    isElectron: isElectron,
    debounce: function (func, wait) {
        let timeout;
        return function (...args) {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(context, args), wait);
        };
    },
    isAuthenticated: function () {
        return fetch(`${functionRoot}/auth`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token") || ''
            },
            credentials: 'include'
        }).then((response) => {
            if (response.ok) {
                return true
            } else {
                return false
            }
        }).catch((error) => {
            return false
        })
    },
    postRequest: postRequest,
    getRequest: function (url, cb, dna = false) { // dna = Do Not Authenticate - rD = race data
        if (url === "/204") dna = true;

        let loadcaller = setTimeout(() => {
            App.callLoadingLine(0, "Waiting for server...")
        }, 1000);

        return fetch(`${functionRoot}${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': dna ? "" : 'Bearer ' + localStorage.getItem("token") || ''
            },
            credentials: dna ? "omit" : "include",
        }).then(async (res) => {
            App.callLoadingLine(-1, "")
            clearTimeout(loadcaller);
            if (!res.ok) {
                throw new Error(`HTTP error! status: ${res.status}`);
            }

            if (res.status === 204) {
                return { code: 204 }
            }

            let response
            try {
                response = await res.json()
            } catch (e) {
                throw e; // Re-throw the error so it can be caught by the catch block
            }

            if (res.status === 401) {
                App.snackCaller(response.message || "Unauthorized. Invalid username or password")
                return { code: 401 }
            }
            if (res.status === 500) {
                App.snackCaller("An error occurred. Please try again later.")
                return { code: 500 }
            }

            response.token && localStorage.setItem("token", response.token)
            response.user && localStorage.setItem("user", JSON.stringify(response.user))

            if (response.redirect) {
                App.navigate(response.redirect)
            }

            if (response.message) App.snackCaller(response.message)

            cb && cb(response)

            return response
        }).catch((error) => {
            App.callLoadingLine(-1, "")
            clearTimeout(loadcaller);
            console.error('Error in getRequest:', error);
            return { message: error?.message || "An error occurred. Please try again later." }
        });
    },
    getUserRank: function (rankID) {
        const ranks = {
            0: "Banned",
            1: "User",
            2: "Artist",
            3: "Game Developer",
            4: "Moderator",
            5: "Administrator",
            6: "Developer"
        }

        return ranks[rankID] || "Unknown"
    },
    decrypt: async function (esrc, setError) {
        let key, iv;

        if (localStorage.getItem(esrc)) {
            const stored = JSON.parse(localStorage.getItem(esrc));
            key = base64urlToArrayBuffer(stored.key);
            iv = base64urlToArrayBuffer(stored.iv);
        } else {
            let res = await fetch(`${functionRoot}/content/getEncryptionKey`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ path: esrc })
            })

            res = await res.json();

            if (res.error) {
                setError(res.error);
                return;
            }

            key = base64urlToArrayBuffer(res.key);
            iv = base64urlToArrayBuffer(res.iv);
            localStorage.setItem(esrc, JSON.stringify({ key: res.key, iv: res.iv }));
        }

        const response = await fetch(esrc);
        let buffer = await response.arrayBuffer();

        const cryptoKey = await window.crypto.subtle.importKey(
            'raw',
            key,
            { name: 'AES-CBC', length: 256 },
            false,
            ['decrypt']
        );

        const decrypted = await window.crypto.subtle.decrypt(
            { name: 'AES-CBC', iv: iv },
            cryptoKey,
            buffer
        ).catch((error) => {
            console.error('Decryption error:', error);
        });

        const conversionMap = {
            "png": "image/png",
            "jpg": "image/jpeg",
            "jpeg": "image/jpeg",
            "webp": "image/webp",
            "gif": "image/gif",
            "mp4": "video/mp4",
            "webm": "video/webm",
            "mov": "video/quicktime",
            "avi": "video/x-msvideo",
            "mkv": "video/x-matroska",
            "apng": "image/apng",
            "flv": "video/x-flv",
            "wmv": "video/x-ms-wmv",
            "m4v": "video/x-m4v"
        };

        let ext = esrc.split('.').pop();
        let type = conversionMap[ext] || 'application/octet-stream';

        const blob = new Blob([new Uint8Array(await decrypted)], { type: type });
        const url = URL.createObjectURL(blob);
        return url;
    },
    async handleFileUpload(fileEvent, isVisible, parent, returnVCID) {
        let file = fileEvent;
        if (!file) return;

        file = new File([file], file.name, { type: "application/octet-stream" });

        const hash = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const buffer = reader.result;
                window.crypto.subtle.digest('SHA-256', buffer).then((hashBuffer) => {
                    const hashArray = Array.from(new Uint8Array(hashBuffer));
                    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
                    resolve(hashHex);
                }).catch(reject);
            };
            reader.onerror = reject;
            reader.readAsArrayBuffer(file);
        });
        console.log('File hash:', hash);

        const CHUNK_SIZE = 6 * 1024 * 1024; // 6MB
        const fileSize = file.size; // Get file size upfront

        // request to get the upload URL
        let upURL = await this.postRequest("/api/content/file/getUploadURL", {
            fileName: file.name,
            fileSize: fileSize,
            isVisible: isVisible || false,
            parent: parent || null
        });

        if (upURL.error || !upURL.uploadId) {
            console.error(upURL.error);
            return;
        }

        let uploadedParts = [];
        let offset = 0; // Track the current offset into the file

        for (const partInfo of upURL.presignedUrls) {
            const { partNumber, url } = partInfo;

            // Calculate the chunk size for the current part.  The last part
            // might be smaller than CHUNK_SIZE
            let chunkSize = CHUNK_SIZE;
            if (offset + CHUNK_SIZE > fileSize) {
                chunkSize = fileSize - offset;
            }

            const progress = Math.round((offset + chunkSize) * 100 / fileSize);
            App.callLoadingLine(progress, `Uploading ${file.name}...`);


            const chunk = file.slice(offset, offset + chunkSize);

            const uploadResponse = await fetch(url, {
                method: 'PUT',
                body: chunk,
                headers: {
                    'Content-Length': chunk.size.toString(), // Important for R2
                    'Content-Type': file.type || 'application/octet-stream'
                }
            });

            if (!uploadResponse.ok) {
                const errorText = await uploadResponse.text();
                throw new Error(`Failed to upload part ${partNumber}: ${uploadResponse.status} ${uploadResponse.statusText} - ${errorText}`);
            }

            for (const [header, value] of uploadResponse.headers.entries()) {
                console.log(`${header}: ${value}`);
            }

            const etag = uploadResponse.headers.get('etag');
            if (!etag) {
                throw new Error(`No ETag received for part ${partNumber}`);
            }

            uploadedParts.push({
                PartNumber: partNumber,
                ETag: etag.replace(/"/g, '') // Remove quotes from ETag
            });
            console.log(`Uploaded part ${partNumber}, ETag: ${etag}`);

            offset += chunkSize; // Increment the offset for the next chunk
        }

        // Complete the upload

        console.log("waiting")

        await new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve()
            }, 1000);
        })

        const completeResponse = await this.postRequest("/api/content/file/completeUpload", {
            uploadId: upURL.uploadId,
            key: upURL.key,
            parts: uploadedParts.sort((a, b) => a.PartNumber - b.PartNumber) // Sort parts by PartNumber
        });

        App.callLoadingLine(-1, "");
        App.snackCaller("Upload complete!");

        console.log('Upload complete!!!');

        return returnVCID ? upURL.vcid : wrapperForMySanity(upURL.UUID);
    },
    Functionify: function (Comp, props) {
        let navigate = useNavigate();
        return <Comp {...props} navigate={navigate} />
    },
    analyticsHandler: function (e, data) {
        if (this.isElectron()) { // use IPC-SCEN for electron
            window.ipcRenderer.invoke('SCEN', {
                event: e,
                data: data
            });
        } else { // XHR-SCEN for web
            if (!localStorage.getItem('PCWUUID')) {
                this.generatePCWUUID();
            }

            let eve = e;

            this.postRequest('/api/scen', {
                event: e,
                data: data,
                PCWUUID: localStorage.getItem('PCWUUID')
            }).catch(e => {
                // try SCII again

                if (!localStorage.getItem('PCWUUID')) {
                    this.generatePCWUUID();
                }

                this.postRequest("/api/scii", {
                    event: "appStart",
                    PCWUUID: localStorage.getItem('PCWUUID') || this.generatePCWUUID(),
                    platform: "web"
                }).catch((e) => {
                    console.log("Error in SCII!!!", e)
                })
                    .then(() => {
                        this.postRequest('/api/scen', {
                            event: eve,
                            data: data,
                            PCWUUID: localStorage.getItem('PCWUUID')
                        }).catch(e => {
                            console.log("Error in SCEN!!!", e)
                        })
                    })


            })
        }
    },
    generatePCWUUID: function () {
        function _randomByte() {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        }
        const uuid = _randomByte() + _randomByte() + '-' + _randomByte() + '-' + '4' + _randomByte().substr(0, 3) + '-' + (Math.floor(Math.random() * 4 + 8)).toString(16) + _randomByte().substr(0, 3) + '-' + _randomByte() + _randomByte() + _randomByte();
        localStorage.setItem('PCWUUID', uuid);
        return uuid;
    },
    randomString: function (length) {
        const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let result = '';
        for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    },
    isURL: function (string) {
        var protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;

        var localhostDomainRE = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/
        var nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/;

        if (typeof string !== 'string') {
            return false;
        }

        var match = string.match(protocolAndDomainRE);
        if (!match) {
            return false;
        }

        var everythingAfterProtocol = match[1];
        if (!everythingAfterProtocol) {
            return false;
        }

        if (localhostDomainRE.test(everythingAfterProtocol) ||
            nonLocalhostDomainRE.test(everythingAfterProtocol)) {
            return true;
        }

        return false;
    },
    base64ToBlob: function (base64String, contentType) {
        const byteCharacters = atob(base64String);
        const byteLength = byteCharacters.length;
        const sliceSize = 1024;
        const byteArrays = [];

        for (let i = 0; i < byteLength; i += sliceSize) {
            const slice = byteCharacters.substring(i, i + sliceSize);
            const byteArray = new Uint8Array(slice.length);
            for (let j = 0; j < slice.length; j++) {
                byteArray[j] = slice.charCodeAt(j);
            }
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return URL.createObjectURL(blob);
    },
    generateRandomAccessKey: function (length = 16) {
        const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let result = 'VCDE-';
        for (let i = length; i > 0; --i) {
            if (i % 4 === 0 && i !== length) result += '-';
            result += chars[Math.floor(Math.random() * chars.length)];
        }
        return result;
    },
    returnCurrentDynamicURL: function () {
        if (isElectron()) {
            // hashrouting
            return window.location.hash.split("#")[1].split("/")
        } else {
            // regular routing
            return window.location.href.split(window.location.hostname)[1].split("/")
        }
    },
    convertImageToWebp: async function (imageFile, quality = 0.7) {
        /**
         * Converts an image file to WebP format using the browser's canvas API.
         * Cleans up the canvas element after use.  All in one function.
         *
         * @param {File} imageFile - The image file to convert. Should be a File object
         * @param {number} [quality=0.7] - The WebP quality (0 to 1, higher is better). Defaults to 0.7.
         * @returns {Promise<File>} - A promise that resolves with a WebP File object, or rejects with an error.
         */

        return new Promise(async(resolve, reject) => {
            if (!imageFile) {
                reject(new Error("Image file is required."));
                return;
            }

            console.log(`Original image size: ${imageFile.size} bytes`);

            // detect if the image is already WebP
            if (imageFile.type === "image/webp") {
                resolve(imageFile);
                return;
            }

            // detect if the image is of a type that is animated, if so, pass it to the server for conversion
            if (imageFile.type === "image/gif") {
                let i = await new Promise((resolve, reject) => {
                    const reader = new FileReader();

                    reader.onload = function (event) {
                        resolve(event.target.result);
                    };

                    reader.onerror = function () {
                        reject(new Error("Error reading image file."));
                    };

                    // read as a hex string
                    reader.readAsDataURL(imageFile);
                });
                postRequest("/api/content/compressImage", {
                    image: i
                }).then((res) => {
                    // res.image is a base64 encoded data URL
                    const parts = res.image.split(';base64,');
                    const contentType = parts[0].split(':')[1];
                    const raw = window.atob(parts[1]);
                    const rawLength = raw.length;
                    const uInt8Array = new Uint8Array(rawLength);

                    for (let i = 0; i < rawLength; ++i) {
                        uInt8Array[i] = raw.charCodeAt(i);
                    }

                    const blob = new Blob([uInt8Array], { type: contentType });

                    const webpFile = new File([blob], imageFile.name.replace(/\..*$/, ".webp"), {
                        type: "image/webp",
                        lastModified: imageFile.lastModified,
                    });

                    console.log(`WebP image size: ${webpFile.size} bytes`);

                    resolve(webpFile);
                })

                return
            }

            const reader = new FileReader();

            reader.onload = function (event) {
                const img = new Image();

                img.onload = function () {
                    const canvas = document.createElement("canvas");
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext("2d");

                    if (!ctx) {
                        reject(new Error("Could not get 2D rendering context."));
                        // Cleanup Canvas (inline)
                        if (canvas.parentNode) {
                            canvas.parentNode.removeChild(canvas);
                        }
                        canvas.width = 0;
                        canvas.height = 0;
                        return;
                    }

                    ctx.drawImage(img, 0, 0);
                    const webpDataURL = canvas.toDataURL("image/webp", quality);

                    // Convert base64 to Blob (File) - inline
                    const parts = webpDataURL.split(';base64,');
                    const contentType = parts[0].split(':')[1];
                    const raw = window.atob(parts[1]);
                    const rawLength = raw.length;
                    const uInt8Array = new Uint8Array(rawLength);

                    for (let i = 0; i < rawLength; ++i) {
                        uInt8Array[i] = raw.charCodeAt(i);
                    }

                    const blob = new Blob([uInt8Array], { type: contentType });


                    const webpFile = new File([blob], imageFile.name.replace(/\..*$/, ".webp"), {
                        type: "image/webp",
                        lastModified: imageFile.lastModified,
                    });

                    console.log(`WebP image size: ${webpFile.size} bytes`);

                    resolve(webpFile);

                    // Cleanup Canvas (inline)
                    if (canvas.parentNode) {
                        canvas.parentNode.removeChild(canvas);
                    }
                    canvas.width = 0;
                    canvas.height = 0;

                };

                img.onerror = function () {
                    reject(new Error("Error loading image."));
                };

                img.src = event.target.result; // Data URL from FileReader
            };

            reader.onerror = function () {
                reject(new Error("Error reading image file."));
            };

            reader.readAsDataURL(imageFile); // Read file as Data URL
        });
    }
}

// leaving this here for now, maybe localstorage is just enough?
const IDB_NAME = 'vcadeDB';
const IDB_STORE = 'appData';

function openDB() {
    return new Promise((resolve, reject) => {
        const request = window.indexedDB.open(IDB_NAME, 1);

        request.onsuccess = (event) => {
            resolve(event.target.result);
        };

        request.onerror = (event) => {
            reject(event.target.error);
        };

        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            db.createObjectStore(IDB_STORE);
        };
    });
}

async function syncSet(key, value) {
    const db = await openDB();
    const transaction = db.transaction(IDB_STORE, 'readwrite');
    const store = transaction.objectStore(IDB_STORE);
    await store.put(value, key);
    return Promise.resolve();
}

async function syncGet(key) {
    const db = await openDB();
    const transaction = db.transaction(IDB_STORE, 'readonly');
    const store = transaction.objectStore(IDB_STORE);
    const value = await store.get(key);
    return Promise.resolve(value);
}

async function syncRemove(key) {
    const db = await openDB();
    const transaction = db.transaction(IDB_STORE, 'readwrite');
    const store = transaction.objectStore(IDB_STORE);
    await store.delete(key);
    return Promise.resolve();
}

async function syncClear() {
    const db = await openDB();
    const transaction = db.transaction(IDB_STORE, 'readwrite');
    const store = transaction.objectStore(IDB_STORE);
    await store.clear();
    localStorage.setItem("ageCheck", "true")
    return Promise.resolve();
}