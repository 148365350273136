import { Component } from "react";
import reactGfm from "remark-gfm";
import remarkRehype from "remark-rehype";
import rehypeHighlight from "rehype-highlight";
import rehypeVideo from 'rehype-video';
import sanitize from "rehype-sanitize";
import rehypeRaw from "rehype-raw";
import RMarkdown from "react-markdown";
import Image from "./Image";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import dark from 'react-syntax-highlighter/dist/esm/styles/prism/material-dark'
import hljs from "highlight.js";

class Markdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            markdown: ""
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.markdown !== nextProps.markdown) {
            return true;
        }

        return false;
    }

    componentDidMount() {
        this.setState({
            markdown: this.props.markdown
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.markdown !== prevProps.markdown) {
            this.setState({
                markdown: this.props.markdown
            });
        }
    }

    render() {
        return (
            <RMarkdown
                children={this.state.markdown}
                skipHtml={false}
                remarkPlugins={[reactGfm, remarkRehype]}
                rehypePlugins={[rehypeVideo, rehypeRaw, sanitize]}
                style={{
                    width: "50%",
                    ...this.props.style
                }}
                components={{
                    img: ({ node, ...props }) => {
                        let src
                        if (props.src.startsWith("http")) {
                            src = props.src
                        } else {
                            src = src = window.NODE_ENV === "development" ? "http://localhost:5000" + props.src : "https://vorecade.com" + props.src
                        }
                        let prop = { ...props, src }
                        return <Image key={props.src} {...prop} style={{ maxWidth: "100%" }} />;
                    },
                    video: ({ node, ...props }) => {
                        return <video {...props} controls style={{ maxWidth: "100%" }} />;
                    },
                    a: ({ node, ...props }) => { // open links in new tab
                        return <a {...props} target="_blank" rel="noopener noreferrer" />;
                    },
                    code(props) {
                        if (!props.className) {
                            return <code {...props} style={{
                                color: "white",
                                backgroundColor: "#333",
                                padding: 5,
                                borderRadius: 5,
                                display: "inline-block",
                                width: "100%",
                                wordBreak: "break-word",
                                whiteSpace: "pre-wrap"
                            }}
                                children={props.children}
                            />
                        }
                        const { children, className, node, ...rest } = props
                        let match = /language-(\w+)/.exec(className || '')
                        match = match || hljs.highlightAuto(String(children)).language
                        return match ? (
                            <SyntaxHighlighter
                                {...rest}
                                PreTag="div"
                                children={String(children).replace(/\n$/, '')}
                                language={Array.isArray(match) ? match[1] : match}
                                style={dark}
                            />
                        ) : (
                            <code {...rest} style={{
                                color: "white",
                                backgroundColor: "#333",
                                padding: 5,
                                borderRadius: 5,
                                display: "inline-block",
                                width: "100%",
                                wordBreak: "break-word",
                                whiteSpace: "pre-wrap"
                            }} className={className}>
                                {children}
                            </code>
                        )
                    }
                }}
            />
        );
    }
}

export default Markdown;