import Button from '@mui/material/Button';
import { Component, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import util from '../util';
import { Box, ButtonBase, Tooltip, Typography } from '@mui/material';
import Image from '../components/Image';
import { FireflyCanvas } from '../components/Fireflies';
import { Windows } from '../components/Icons/Windows';

class Background extends Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps) {
        return false;
    }

    render() {
        return (
            <>
                {
                    // put the games's thumbnails in a square grid, put every game in the category, use game images if thumbnails are not enough
                    [...this.props.games, ...this.props.games, ...this.props.games].slice(0, 40).sort(() => Math.random() - 0.5).map((game, i) => {
                        return (
                            <Image
                                key={i}
                                src={game.thumbnail}
                                style={{
                                    aspectRatio: "73 / 45",
                                    width: 360,
                                    objectFit: "cover",
                                    transform: (i % 2 === 0) ? "scale(1.1)" : "none",
                                    transition: "0.3s ease-in-out",
                                    margin: (i % 2 === 0) ? "15 0 5 0" : "0 5 0 15",
                                }}
                            />
                        )
                    })
                }</>
        )
    }
}

const Home = () => {
    const navigate = useNavigate();
    const [games, setGames] = useState([]);
    const [latestVersion, setLatestVersion] = useState({});

    useEffect(() => {
        // scroll to the top of the page
        document.getElementById("root").scrollTo(0, 0);
        // try to get games from the cache
        caches.open("request-cache").then(async (cache) => {
            let response = await cache.match("INDEX_GAMES");

            if (response) { // only set if it hasn't been set by the network fetch
                response = await response.json()
                setGames(response);
            } else {
                util.getRequest(`/api/games`, (res) => {
                    setGames(res.games);
                    cache.put("INDEX_GAMES", new Response(JSON.stringify(res.games)));
                })
            }
        })

        util.postRequest("/api/glcuiuc", {
            channel: "Stable",
            currentVersionID: -1
        }).then((res) => {
            console.log(res)
            try {
                setLatestVersion(res.version);
            } catch (e) {

            }
        })

        document.getElementById("root").style.overflow = "hidden";

        return () => {
            document.getElementById("root").style.overflow = "auto";
        }
    }, []);

    /*
    <Button
                onClick={() => {
                    window.open("https://cdn.vorecade.com/defaults/downloads/clientVersionDownloads/Vorecade%20v30.zip", "_blank");
                }}
            >download</Button>
    */
    return (
        <Box
            sx={{
                position: "relative",
                width: "105%",
                marginLeft: "-25px",
                marginRight: "-25px",
                height: "calc(100vh - 160px)",
                display: "flex",
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: -1,
                    pointerEvents: "none",
                }}
            >
                <Box
                    className="imageShow"
                    sx={{
                        // skew it in 3d space as if we are looking at a wallpaper of images on the floor from above
                        transform: "rotateX(45deg) rotateY(0deg) rotateZ(325deg)",
                        display: "flex",
                        flexWrap: "wrap",
                        // width is the highest possible multiplier of 240 that fits the screen, calculate it by dividing the screen width by 240 and rounding down
                        width: (Math.floor(1080 * 2 / 240) * 240) * 3,
                        minWidth: "100%",
                        marginTop: "-300px",
                        marginLeft: "-350px",
                        position: "relative",
                        top: 0,
                        left: 0,
                        transition: "0.3s ease-in-out",
                        scale: 1.2
                    }}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "300%",
                            height: "300%",
                            background: "rgba(0,0,0,0.5)",
                            backdropFilter: "blur(10px)",
                            zIndex: 2,
                        }}
                    />
                    <Box
                        sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            zIndex: 1,
                            scale: 3
                        }}
                    >
                        <FireflyCanvas
                            numParticles={150}
                            colors={["#FF69B4", "#FF4500", "#FFFF00", "#7CFC00", "#00FFFF", "#1E90FF", "#8A2BE2", "#FF1493", "#DC143C", "#FFA500", "#BDB76B", "#32CD32", "#00CED1", "#4682B4", "#9400D3", "#FF00FF", "#8B0000", "#FFD700", "#00FF00", "#008080", "#4169E1", "#800080", "#FA8072", "#F0E68C", "#ADFF2F"]}
                        />
                    </Box>

                    <Box
                        sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            zIndex: 1,
                            scale: -3
                        }}
                    >
                        <FireflyCanvas
                            numParticles={150}
                            colors={["#FF69B4", "#FF4500", "#FFFF00", "#7CFC00", "#00FFFF", "#1E90FF", "#8A2BE2", "#FF1493", "#DC143C", "#FFA500", "#BDB76B", "#32CD32", "#00CED1", "#4682B4", "#9400D3", "#FF00FF", "#8B0000", "#FFD700", "#00FF00", "#008080", "#4169E1", "#800080", "#FA8072", "#F0E68C", "#ADFF2F"]}
                        />
                    </Box>

                    {games?.length > 0 && <Background games={games} />}

                </Box>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    zIndex: 9999,
                }}
            >
                <Typography
                    variant="h1"
                    sx={{
                        color: "white",
                        fontSize: "5rem",
                        marginBottom: 5,
                        fontFamily: "Montserrat"
                    }}
                >
                    Vorecade
                </Typography>

                <Typography
                    variant="h2"
                    sx={{
                        color: "white",
                        fontSize: "2rem",
                        marginBottom: 5,
                        fontFamily: "Montserrat"
                    }}
                >
                    Download the Windows client
                </Typography>

                <ButtonBase
                    sx={{
                        borderRadius: 10,
                        padding: 5,
                        marginTop: 10,
                        transition: "0.3s ease-in-out",
                        "&:hover": {
                            transform: "scale(1.1)"
                        },
                        zIndex: 5
                    }}
                    onClick={() => {
                        window.open("REPLACEME", "_blank");
                    }}
                >
                    <Box>
                        <Typography
                            variant="h2"
                            sx={{
                                color: "white",
                                fontSize: "2rem",
                                fontFamily: "Montserrat"
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center"

                                }}
                            >
                                <Box
                                    sx={{
                                        scale: 0.6
                                    }}
                                >
                                    <Windows />
                                </Box>
                                <Typography
                                    variant="h2"
                                    sx={{
                                        color: "white",
                                        fontSize: "2rem",
                                        fontFamily: "Montserrat",
                                        marginLeft: 2
                                    }}
                                >
                                    Windows 10 / 11 [x64]
                                </Typography>

                            </Box>
                            v{latestVersion.versionID} - Stable<br />
                            <i>Released on {new Date(latestVersion.cdnObject?.created)?.toLocaleDateString()}</i><br />
                            {Math.trunc(115 + (latestVersion.cdnObject?.size / 1024 / 1024))} MB
                        </Typography>
                    </Box>
                </ButtonBase>

                <Typography
                    variant="h2"
                    sx={{
                        color: "white",
                        fontSize: "2rem",
                        marginBottom: 5,
                        fontFamily: "Montserrat",
                        marginTop: 10,
                        position: "absolute",
                        bottom: -100,
                    }}
                >
                    Linux version coming soon...
                </Typography>


            </Box>
        </Box>
    );
};

export default Home;