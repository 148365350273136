import Button from '@mui/material/Button';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import util from '../../util';
import { Typography, Box, Tabs, Tab } from '@mui/material';
import Markdown from '../../components/Markdown';

const docs = {
    "welcome": `
# Welcome to the Vorecade API Documentation!
***PLEASE NOTE THAT THIS API IS PRIMARILY INTENDED FOR ANALYTICS USE IN GAMES HOSTED ON VORECADE AT THE TIME BEING. THE API WON'T RETURN ANY INFORMATION REGARDING USERS.***

This documentation is intended to help you understand how to use the Vorecade API to interact with the Vorecade platform. The Vorecade API is a RESTful API that allows you to interact with the Vorecade platform programmatically. This documentation will cover the various endpoints available in the Vorecade API, as well as the different types of requests that can be made to these endpoints. If you have any questions or need further assistance, please don't hesitate to contact us on our [Discord server](https://discord.gg/fvjfp8NJMg).

You can use the navigation bar at the top of the page to browse the different sections of this documentation.

## API Base URL
The base URL for the Vorecade API is \`https://vorecade.com\`

Every example in this documentation assumes that you are using this base URL to make requests to the Vorecade API.
Example: \`https://vorecade.com/api/...\`

Enjoy using the Vorecade API!
`,
    "setting up custom analytics": `
# Setting Up Custom Analytics

Custom analytics events are a powerful tool that allows you to track and analyze user interactions within your games. By sending custom analytics events to Vorecade, you can gain valuable insights into how players are engaging with your game. This data can help you make informed decisions about your game design, balance, and player experience.

## Getting Started

*I assume you already have a Vorecade account, otherwise you wouldn't be here.*

You first need to create a game to start sending custom analytics events. You can create a game by going to the Vorecade app and following these steps:

### Creating a Game

1. Go to **Content Manager > Game Management** in the Vorecade app.
2. Click on the **New Game** button.
3. Fill in the required information for your game, such as the name and platform.
4. Click on the **Create Game** button.

### Creating a Custom Event Schema

Once you have created a game, you need to create a custom event schema. This schema defines the structure of the custom events you want to send. You can create a custom event schema by following these steps:

1. Go to **Content Manager > Game Management > Your Game > Analytics > Custom Events** in the Vorecade app.
2. Write down the event name and the properties you want to track.

![Write Event Name](https://vorecade.com/api/content/file/3ed81963-8ee9-4421-aa4e-7936652311d1)

3. Click on the **Add Property** button to add properties to the event schema.

![Add Property](https://vorecade.com/api/content/file/a5a8bea6-866d-49d7-919f-12844e8c11e3)

4. Fill in the required information for each property, such as the name, type, and description.
5. Click on the **Save Event** button to save the custom event schema. ***YOU CANNOT DELETE OR MODIFY PROPERTIES AFTER SAVING.***

![Save Event](https://vorecade.com/api/content/file/07cd9e54-fce6-4dc6-b4ea-6ed0bfaa28d2)

6. You can now start sending custom analytics events to Vorecade using the custom event schema you created! Good job!

***Event UUID will be shown after you save the event. You'll need this UUID to send events.***

# Things to Keep in Mind

- Event names must be unique within a game. 
- You should **ALWAYS** use your eventID/UUID when sending events for the most accurate data.
- You cannot use internal event names Vorecade uses. You can find a list of these names below.
- Deleting Events/Event Data is permanent and cannot be undone.
- Property names are case-sensitive.
- Property validation failures will result in the incoming event being discarded.
- You can add new properties to an existing event schema, but you cannot remove or modify existing properties.
- You can send any number of properties with your custom event, but you should only send the properties that are defined in the custom event schema.
- Custom events does not require Vorecade to be running in the background or to be installed on the user's device.

# Example Use Cases

- You could send custom events when the player wins a fight, completes a quest, or interacts with an NPC.
- You could track player progress and use that data to balance the game difficulty.
- You could track player engagement and use that data to improve the game's retention rate.
- You could track how many players are using a specific feature in your game.
- You could track how many times is your game being played in a day regardless of the game being launched from the Vorecade app or not.

# Reserved Event Names

***You cannot use these event names for your custom events. Using these event names will redirect your data to the internal event collector and most likely fail to validate.***

- clientUnhandledException
- clientRendererUnhandledException
- gameLaunch
- gameExit
- gameUpdate
- gameInstall
- gameUninstall
- appStart
- gamePageVisit
- gamePageLeave 


`,
    "authenticating clients": `
# Authenticating API Clients

Every API request made to the API must be authenticated and be correlated with a specific clientID. This is to help analyze and monitor the usage statistics of the whole site. clientIDs are UUIDs in the format of UUIDv4. They do not contain personally identifiable information and must be generated on the client side registering a new clientID.

### What if I don't provide a clientID/invalid clientID?
It'll be discarded. The server will simply not process your request, and you'll receive a 400 Bad Request status code.

## When to register a new clientID?
You can register a new clientID and hardcode it in your application if you'd like, but it's recommended to generate a new clientID for every unique user. This is to help us better understand the usage patterns of the API and to help us improve the service.

You should **NEVER** register a new clientID every time your application starts or every time you send a request. This will flood the database with unnecessary clientIDs and may result in your requests being rate-limited or blocked.

### What's the rate limit?
The rate limit should be more than enough for most applications. If you're hitting the rate limit, you're probably doing something wrong. If you legitimately need a higher rate limit, please contact us on our Discord server.

## How do I register a clientID?
You can register a clientID by sending a POST request as follows:

\`\`\`json
POST /api/scii
Content-Type: application/json

{
    "PCWUUID": "YOUR_RANDOMLY_GENERATED_UUIDV4",
    "platform": "web" // MUST BE SET TO "web" FOR API CLIENTS
}
\`\`\`
*SCII stands for "SEND CLIENT IDENTIFICATION INFORMATION"*

Replace \`YOUR_RANDOMLY_GENERATED_UUIDV4\` with a UUIDv4 generated on the client side.

### Example
\`\`\`json
POST /api/scii
Content-Type: application/json

{
    "PCWUUID": "f47ac10b-58cc-4372-a567-0e02b2c3d479",
    "platform": "web"
}
\`\`\`

### Response

If the clientID is successfully registered, the server will respond with a 200 OK status code and a JSON object.

\`\`\`json
{
    "message": "ok",
}
\`\`\`

### Error Responses

If the request is sent with an invalid platform, the server will respond with a 400 Bad Request status code and a JSON object.
\`\`\`json
{
    "message": "Invalid platform",
}
\`\`\`

If the PCWUUID field in the request is empty, the server will respond with a 400 Bad Request status code and a JSON object.
\`\`\`json
{
    "message": "Missing fields"
}
\`\`\`
`,
    "custom analytics": `
# Custom Analytics Events

The Vorecade API allows you to send custom analytics events to the Vorecade platform. These events can be used to track user interactions within your games or anything else you want to track.

## Creating a Custom Event Schema
Before you start sending custom events, you need to create a custom event schema. This schema defines the structure of the custom events you want to send. You can create a custom event schema by using the Vorecade app.

**Content Manager > Game Analytics > Your Game > Custom Events**

Follow the instructions on the page to create a custom event schema. Please do note that the schema must be created before you can start sending custom events.

### Important Note on Creating Custom Events
- Event names must be unique within a game.
- You should **ALWAYS** use your eventID/UUID when sending events for the most accurate data.
- Events **cannot** be deleted once created.
- Event names are case-sensitive.
- Event property names are case-sensitive.
- Event validation failures will result in the incoming event being discarded.
- You can add new properties to an existing event schema, but you cannot remove or modify existing properties.

## Sending Custom Events
Once you have created a custom event schema, you can start sending custom events to the Vorecade platform. You can send custom events by making a POST request to the following endpoint:


***You can get your event UUID from the Vorecade app.***

\`\`\`json
POST /api/scen
Content-Type: application/json

{
    "PCWUUID": "YOUR_CLIENT_ID",
    "eventID": "YOUR_EVENT_UUID",
    "data": {
        "stringProperty": "someString",
        "numberProperty": 69,
        "booleanProperty": true
        ...
    }
}
\`\`\`

### Example

\`\`\`json
POST /api/scen
Content-Type: application/json

{
    "PCWUUID": "f47ac10b-58cc-4372-a567-0e02b2c3d479",
    "eventID": "f47ac10b-58cc-4372-a567-0e02b2c3d490",
    "data": {
        "level": 1,
        "score": 100
    }
}
\`\`\`

### Response

If the custom event is successfully sent, the server will respond with a 200 OK status code and a JSON object.

\`\`\`json
{
    "message": "ok"
}
\`\`\`

### Error Responses

If the request is sent with an invalid clientID, the server will respond with a 400 Bad Request status code and a JSON object.

\`\`\`json
{
    "message": "Client not found"
}
\`\`\`

If the request is sent with an invalid event values such as a string in a number field, a non existent property field etc. the server will respond with a 400 Bad Request status code and a JSON object.

\`\`\`json
{
    "message": "Error explaining what was expected and what was received"
}
\`\`\`

If the request is sent with an event name that does not exist, the server will respond with a 400 Bad Request status code and a JSON object.

\`\`\`json
{
    "message": "No schema found for event EVENT_NAME"
}
\`\`\`

## Viewing Custom Event Analytics

You can view custom event analytics in the Vorecade app. Go to **Content Manager > Game Analytics > Your Game > Custom Events > Custom Event Graphs** to view custom event analytics for your game. You can even filter by the day and see individual event payload data too!

## More Information

- Enums (Predefined values) for strings can be sent as you would send a regular string value.
- Numbers can only be sent as integers, floats are not supported.
- Boolean values can only be true or false. Sending any other value will result in a validation error and the fabric of reality being torn apart.
- You can send any number of properties with your custom event, but you should only send the properties that are defined in the custom event schema.

`,
    "rpg maker plugin": `
# RPG Maker MV/MZ Plugin

The Vorecade RPG Maker plugin allows you to easily integrate the Vorecade API into your RPG Maker MV/MZ games. The plugin provides a simple interface for sending custom analytics events to the Vorecade.

***It's strongly recommended that you read the "Setting Up Custom Analytics" section before using the plugin. It'll get you familiarized with how the system works.***

## Features

- Send custom analytics events to Vorecade.
- Automatic error reporting to Vorecade.
- Advanced error handling replacing the default RPG Maker error handling. Check the **Error Handler** section for more information.
- Ability to show custom notifications to the player. Check the **Usage** section for more information.
- Debug mode for testing out the plugin easily. Check the **Debug Mode** section for more information.

## Usage Examples

- You could send custom events when the player wins a fight, completes a quest, or interacts with an NPC.
- You could track player progress and use that data to balance the game difficulty.
- You could track player engagement and use that data to improve the game's retention rate.

You can use the plugin to track, quantify and analyze player interactions within your game. This data can help you make informed decisions about your game design and improve the player experience. The possibilities are endless! 

# Installation

To install the Vorecade RPG Maker plugin, follow these steps:

### [Download the Plugin Here](https://vorecade.com/api/content/file/4a913748-f891-4957-8cdd-981c88867c91)

1. Download the plugin from the link above.
2. Extract the contents of the downloaded ZIP file.
3. Copy the extracted folder to the \`js/plugins\` directory of your RPG Maker project.
4. Open the RPG Maker MV/MZ editor.
5. Go to the Plugin Manager.


### RPG Maker MV

6. Select the Plugin

![Select the Plugin](https://vorecade.com/api/content/file/7fb6fe85-b8d5-4c62-9f4e-521cd9a9ff92)

### RPG Maker MZ

6. Select the Plugin

![Select the Plugin](https://vorecade.com/api/content/file/91e1a51a-b774-4357-8685-ca947625ea64)

7. Set the Plugin Parameters

Setting gameID is required for automatic error reporting to work. Check the **Finding your gameID** section to find your gameID.
![Set the Plugin Parameters](https://vorecade.com/api/content/file/aa7e5d91-7355-46f0-8057-e1295dc87856)

8. Apply the settings and save.

# Usage

**The plugin usage varies depending on the RPG Maker version you're using. Please refer to the appropriate section below for instructions on how to use the plugin.**

*In both cases, you'll be using the Plugin Command feature in RPG Maker. Insert the command block in any of your scripts to use the plugin.*

## RPG Maker MV

### Sending Custom Events (sendEvent command)

You can send custom events using the \`sendEvent\` command. The command usage is as follows:

\`\`\`
sendEvent eventID data
\`\`\`

- \`eventID\`: The UUID of the custom event you want to send.
- \`data\`: A string where you can specify the data you want to send with the event.

#### Data Format
Separate each property with a semicolon. Each property should be in the format \`key=value\`. Spaces in strings are allowed. Do not use spaces around the semicolon or equals sign.

### Example

\`\`\`
sendEvent 41672968-0797-45a8-adb2-6f124c8cc377 stringtest=TESTER STRING;booltest=true;numtest=69
\`\`\`

You should see the event being sent in the console. If you also have the Debug Mode enabled, you should see these notifications in the game as well.

![Event Example](https://vorecade.com/api/content/file/27def8a5-cfd7-405d-b434-33748bdd0bfe)

### Custom Notifications (showNotification command)

You can show custom notifications to the player using the \`showNotification\` command. The command usage is as follows:

\`\`\`
showNotification message;type;durationInMs
\`\`\`

- \`message\`: The message you want to display in the notification.
- \`type\`: The type of the notification. Can be \`success\`, \`warning\`, or \`error\`.
- \`durationInMs\`: The duration of the notification in milliseconds.

### Example

\`\`\`
showNotification TEST MESSAGE WOO;warning;2000
\`\`\`

You should see a notification like this that disappears after 2 seconds:

![Notification Example](https://vorecade.com/api/content/file/d7de7d04-7a42-4d01-85ba-01f2b5325bec)

## RPG Maker MZ

### Sending Custom Events (sendEvent command)

You can send custom events using the \`sendEvent\` command. The usage is the same as RPG Maker MV. You can also read the on-screen instructions in the plugin parameters.

### Custom Notifications (showNotification command)

You can show custom notifications to the player using the \`showNotification\` command. The usage is the same as RPG Maker MV. You can also read the on-screen instructions in the plugin parameters.

# Debug Mode

The plugin comes with a debug mode that allows you to test out the plugin easily. To enable debug mode, set the \`debug\` parameter to \`true\` in the plugin parameters.

When debug mode is enabled, you'll see notifications in the game whenever you send a custom event or show a notification. This is useful for testing out the plugin and making sure everything is working as expected.

You'll also see this "Debug Mode Enabled" notification in the game when you start the game.

![Debug Mode Enabled](https://vorecade.com/api/content/file/16d556e3-f220-40cf-9d6d-f9140cc80ac0)

# Error Handler

The plugin comes with an advanced error handler that replaces the default RPG Maker error handling. This error handler automatically sends error reports to Vorecade whenever an error occurs in the game, provided that the gameID is set in the plugin parameters.

To set the gameID, go to the Plugin Manager and set the gameID parameter to your gameID. Check the **Finding your gameID** section to find your gameID.

When an error occurs in the game, instead of showing the default RPG Maker error message, the error handler will send an error report to Vorecade. You can view these error reports in the Vorecade app. The player will see a custom error message like this:

![Error Message](https://vorecade.com/api/content/file/57470c71-65a3-4e38-927a-0c97171882ce)

## Testing the Error Handler

To test the error handler, you can force an error in the game. If you enable debug mode, pressing the **F1** key will force an error in the game. You should see the error message and the error report being sent to Vorecade.


# Finding your gameID

Set your gameID so the plugin knows which game to send the events to. You can find your gameID in these places:
- The URL of your game on the Vorecade website.

![Game URL](https://vorecade.com/api/content/file/12d7a232-3b89-4781-86c6-da0a52917dee)

- Content Manager > Game Management > Your Game > Analytics next to the Game Name as (ID: gameID)
`,
    "unity plugin": `
## **Main script**

#### **Importing and Setup**

### [Download the Plugin Here](https://vorecade.com/api/content/file/17cf0f24-61fa-48af-9b01-85bd43949da9)

To make the script work you need to add it to the scene. Other scripts can just find it by \`FindObjectOfType<VorecadeAPI>()\` or by direct link thru the inspector.

Script has the namespace Vorecade, so don't forget to import it.
You can toggle Don't Destroy to automatically move script between scenes. If you use this, duplicates will be destroyed automatically at \`Awake()\`, so use \`FindObjectOfType\` only at Start() in this case.
Debug mode will show you all messages (errors and successes) in console. You can enable only errors message by toggle Only Errors in inspector.
hmal (Vorecade author) recommends to use only one UUID for one user, so by default it saves into PlayerPrefs with key "VorecadeUUIDKey" and restores it at every launch.
Can be disabled by toggling Save UUID in inspector.

#### **Get all events automatically**

You don't need to write all events manually; you can just import them from Vorecade itself!
First open VorecadeAPI Tool in Window=>VorecadeAPI

![](https://vorecade.com/api/content/file/1acd4eba-73b6-4f17-964c-51c6b0add632)

Then you need to select your VorecadeAPI script, but if it's exists on the scene it'll be added automatically.
Next you need your session token. To get your session token, open your browser, login to your developer account and press F12.

**Chromium Based Browsers:**
Application => Local Storage => https://vorecade.com => token

![](https://vorecade.com/api/content/file/2ea1bfe6-20a5-4ea6-8529-031576bb4580)

**Firefox:**
Storage => Local Storage => https://vorecade.com => token

![](https://vorecade.com/api/content/file/a1bae246-494f-4729-8d66-fc0c8830c0e7)

Just copy-paste this into token field in editor.
Now you need your game's ID. You can learn it after adding a custom event in the Vorecade app. It'll be shown below the Event ID

![](https://vorecade.com/api/content/file/c673e8c1-3de2-49ef-80a8-0dfa39f3881c)

If you do everything correctly, a button called “Get events” will enabled. Click and events will be added/updated automatically (you can use it to automatically add/remove properties that you changed in the app too)

![](https://vorecade.com/api/content/file/0232663c-35e1-4af2-9dfc-b6d9ec1e18f0)

Script will **NOT** save your token for security reasons, but remember that you should **NEVER** put your token in your finished build as it will give unrestricted access to your account till it gets automatically revoked after 2 weeks after its initial creation. **2FA or SSM (Single Session Mode) WILL NOT SAVE YOU**.

### **Events**

Now when all events are imported, we can move to the next step. But if something goes wrong when importing events, you can add events manually by yourself. Do note that any events that aren't registered in Vorecade **WILL NOT WORK**.
All events are in this list, you can freely add/remove it yourself by referencing your Custom Events page properties.

![](https://vorecade.com/api/content/file/7d8f03c0-e020-4e1a-99fa-a67d0387f3cc)

Name - how event is called inside your game. Automatically names same as in Vorecade, but you can change it if you want.
UUID - unique ID of the event. You can get it in events page in Vorecade. Select your event and it'll show as below:

![](https://vorecade.com/api/content/file/a1630b88-7193-4cfc-bbeb-2f0af9a4546f)

Clear On Send - if you want to clear all variables after sending an event enable this. Should be used if you are going to send multiple instances of the same event with different property values.
Vars - all properties of the event. Better to not add it manually. All data of property must be same as in Vorecade event's page if you are going to add it manually.

![](https://vorecade.com/api/content/file/73c6471c-6cd0-4d70-b572-0d9e91f6e1a5)

![](https://vorecade.com/api/content/file/0d41b952-22c9-40e5-a183-cca4b361d9ac)

**Incorrect settings might cause your events to fail validation and won't save.**



Let's see what it has:
Name - Name of this property.
Type – This'll have some variety. Compared from Kind column on event's page
  Bool - same as on event's page
  String - use it only if Enums column have “-”
  Int - Number. Only integer values! Floats will not work!
  Enum - set this if you've set enums
Required - is this property required. Set it true if on event's page property have tick
Use Min – Leave this disabled if you didn't set anything for the minimum value.
Min – Set as the same value in the Minimum Column if you've set anything.
Use Max – Leave this disabled if you didn't set anything for the maximum value.
Max - Set as the same value in the Maximum Column if you've set anything.
Min/Max can be used for Int and String types at same time (in string it compares it to the string's length)

Enums - Add any Enums that you've added whilst creating your property. Enums are case-sensitive.
That's all. If you done it all right, your event is ready to use!

## **UnityEvents**

API has 2 UnityEvent which can be used to control what API does
- onRegistered - Invokes when API successfully registers the client.
- onRegisteredFail\<string\> - Invokes if registration fails for some reason, returns a string error message. You can force Registering by Register() function
- onWebError\<string\> - Invokes when sending event fails for some reason. Returns a string error message.

You can add action to event from Inspector itself, but also can be added from code, that will be shown later.

## **Coding**

Script will automatically register the user (once per game launch, even if you use different scripts in different scenes, it still will be registered once per game launch)
First you need to add the Vorecade namespace to the script that will send the event.

\`using Vorecade;\`

Next you need to add Vorecade script as a variable

\`VorecadeAPI api;\`

You can make it public or find it automatically, to find it automatically, add this in the Start() block

\`\`\`cs
private void Start()
{
    api = FindObjectOfType<VorecadeAPI>();
}
\`\`\`

Now you can use the API from your script. Let's send an event that we have above.
To set a value for a property, use api.setVar(), in debug mode, it'll also log the errors if there's any during execution.

It has 3 variants:

\`SetVar(string eventName, string var, string value)\` where

- eventName - name of event where we want to save
- var - name of property that we want to set

value - what we want to save into this property (will be converted automatically, just use \`.ToString()\` ). Also, for enums you can use an integer number (if you have the same enum in your code, use \`(int)enum_var).ToString()\`) or string from enum itself.

\`SetVar(VorecadeAPI_Event _event, string var, string value)\` – all the same, but you send _event as object instead of writing its name

\`SetVar(VorecadeAPI_Event_Var var, string value)\` – all the same, but you send Property as object without event itself.

To send the event to Vorecade, use SendEvent(event) where event is the name of event or it's object instance.
API automatically creates a JSON string and send all data as you need.
Example of sending an event:

\`\`\`cs
void WriteAndSend()
{
    api.SetVar("testEvent", "str", "test2");
    api.SetVar("testEvent", "bl", "false");
    api.SendEvent("testEvent");
}
\`\`\`

Also, you can get event and variable as objects by next fuctions:

\`GetEvent(event_name)\` - returns you event object by name

\`GetVar(event_name/event_object, var_name)\` - return you Property object by name

If you want to subscribe to an event, use this:

\`\`\`cs
onRegistered.AddListener(() =>YourFunction()); //You just call your function
onRegisteredFail.AddListener((string s)=>YourFunction(s)); // return error message as a variable to your function
// And finally, you can get is player registered and their uuid by next code:
bool reg = VorecadeAPI.registered;
string uuid = VorecadeAPI.uuid;
\`\`\`

That's all you need to work with the events API of Vorecade. Good luck!

### **This documentation was written by HenBor, creator of [Vore Mini Stories](https://vorecade.com/games/9/) and this plugin.**

`
}

const Home = () => {
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState(0);

    function CustomTabPanel(props) {
        const { children, value, index, cs, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3, width: "100%", ...cs }}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    return (
        <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={currentTab} onChange={(e, v) => {
                    setCurrentTab(v);
                }}>
                    {Object.keys(docs).map((doc, i) => {
                        return <Tab key={i} label={doc} />
                    })}
                </Tabs>

                {Object.keys(docs).map((doc, i) => {
                    return (
                        <CustomTabPanel key={i} value={currentTab} index={i} cs={{}}>
                            <div
                                style={{
                                    textAlign: "left",
                                }}
                            >
                                <Markdown markdown={docs[doc]} />
                            </div>
                        </CustomTabPanel>
                    );
                })}
            </Box>
        </Box>
    );
};

export default Home;